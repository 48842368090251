import { faCheck, faPen, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Input } from "_components/Input";

const { Box, ButtonIcon } = require("_components");
const { useState } = require("react");

const LigneScope = (props) => {
  return (
    <tr className="ligneFournisseurScope">
      
      <td className="col3 background-color">
        <Input
          accessor={"" + props.scope.id + ".date"}
          value={props.scope.date}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col3 background-color">
        <Input
          accessor={"" + props.scope.id + ".facteurEmission"}
          value={props.scope.facteurEmission}
          type="decimalTable"
          placeholder=""
          handleBlur={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col4 background-color" style={{ width: "55px" }}>
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          onClick={() => {
            props?.deleteLineScope(props.scope.id);
          }}
          className="btn btn-danger deleteLineScope"
          style={!props.editing ? { display: "none" } : {}}
        />
      </td>
    </tr>
  );
};

const BoxScope3 = (props) => {
    const [savedFournisseur, setSavedFournisseur] = useState({});

    const [editingScope, setEditingScope] = useState(false);
    const [scopes, setScopes] = useState([]);
  
    const handleClickEdition = () => {
      setSavedFournisseur({...props.fournisseur});
      props.handleEditing(true);
      setEditingScope(true);
      if (props.fournisseur.historiqueScope3 == null) {
        setScopes([]);
      } else {
        setScopes(props.fournisseur.historiqueScope3.map(e => {return {...e};}));
      }
    };
  
    const handleClickUndo = () => {
      props.handleChange("fournisseur", savedFournisseur);
      props.handleEditing(false);
      setEditingScope(false);
      setScopes([]);
    };
  
    const handleClickValidate = () => {
      props.handleChange("historiqueScope3", scopes);
      props.handleEditing(false);
      setEditingScope(false);
      setScopes([]);
    };



    const addLineScope = () => {
        let newLineId = Math.min(...scopes.map((el) => el.id), 0) - 1;
    
        let newScope = {
          id: newLineId,
          facteurEmission: 0,
          dateFin: null,
        };
        setScopes([...scopes, newScope]);
    };
    
    const deleteLineScope = (scopeId) => {
        let scopetmp = scopes.map(e => {return {...e};});
        scopetmp.splice(
            scopetmp.findIndex((el) => el.id == scopeId),
            1
        );
        console.log(scopes, scopetmp);
        setScopes([...scopetmp]);
    };
    
    const handleChangeScope = (accessor, value) => {
        console.log("wut");
        let accessorSplit = accessor.split(".");
        var id = accessorSplit[0];
        var realAccessor = accessorSplit[1];

        let scopetmp = scopes.map(e => {return {...e};});

        let scopeToUpdate = scopetmp.find((el) => el.id == id);
        scopeToUpdate[realAccessor] = value;

        scopetmp.splice(
            scopetmp.findIndex((el) => el.id == id),
            1,
            scopeToUpdate
        );

        setScopes(scopetmp);
    };

    return (
    <Box
        header={
            <>
            <div className="col-6 align-self-center py-2">
                <div>Scope3</div>
            </div>
            <div className="col-6 text-end py-2">
                <ButtonIcon
                icon={faPlus}
                iconSize="sm"
                onClick={() => addLineScope?.()}
                className="btn btn-success addEnfant"
                style={!editingScope ? { display: "none" } : {}}
                />
                <ButtonIcon
                icon={faCheck}
                className="btn btn-success text-light ms-1"
                style={
                    !editingScope ? { display: "none" } : { width: "42px" }
                }
                onClick={handleClickValidate}
                />
                <ButtonIcon
                icon={faTimes}
                className="btn btn-danger text-light ms-1"
                onClick={handleClickUndo}
                style={
                    !editingScope ? { display: "none" } : { width: "42px" }
                }
                />
                <ButtonIcon
                icon={faPen}
                className="btn btn-secondary text-light ms-1"
                onClick={handleClickEdition}
                style={
                    props.editing ? { display: "none" } : { width: "42px" }
                }
                />
            </div>
            </>
        }
        body={
            <>
            <table
                className={
                "text-uppercase table table-sm " +
                (editingScope
                    ? scopes.length > 0
                    ? " "
                    : " table-bordered"
                    : props.fournisseur.historiqueScope3?.length > 0
                    ? " "
                    : " table-bordered")
                }
            >
                <thead
                className={
                    "sticky-top" +
                    (editingScope
                    ? scopes.length == 0
                        ? " theadBorderRadius"
                        : ""
                    : props.fournisseur.historiqueScope3?.length == 0
                    ? " theadBorderRadius"
                    : "")
                }
                >
                <tr>
                    <th>Date</th>
                    <th>Facteur émission</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {editingScope
                    ? scopes
                        ?.slice()
                        .map((scope) => (
                        <LigneScope
                            key={scope.id}
                            scope={scope}
                            handleChange={handleChangeScope}
                            editing={editingScope}
                            deleteLineScope={deleteLineScope}
                        />
                        ))
                    : props.fournisseur.historiqueScope3
                        ?.slice()
                        .map((scope) => (
                        <LigneScope
                            key={scope.id}
                            scope={scope}
                            handleChange={handleChangeScope}
                            editing={editingScope}
                            deleteLineScope={deleteLineScope}
                        />
                        ))}
                </tbody>
            </table>
            </>
        }
    />);
};

export {BoxScope3};