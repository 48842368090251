import React from "react";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
} from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import {
  ConsultationService,
  ProduitInterneService,
  SourceService,
  PersonnelService,
  StatutsConsultationService,
} from "_services";
import { IfNullToText, RedirectTo, ToLocaleDateString } from "_utils";
import { PropTypes } from "prop-types";

import { Helmet } from "react-helmet";
import { App } from "App";

class BaseConsultation extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base consultation</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Consultation"
          titreFiltre="Base Consultation"
          service={ConsultationService.getAll}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Consultation", "Extraction") ==
            "RW"
          }
          serviceExtract={ConsultationService.Extract}
          module="Consultation"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/consultations/add");
          }}
          orderByDefault="reference"
          orderDefault="DESC"
          localStorageKeyColumns="BaseConsultationColumns"
          objectFieldToExport={{
            client: "nom_Client",
            contact: "nom_Contact",
          }}
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Actif",
              accessor: "actif",
              defaultValue: {
                id: 0,
                display: "Oui",
                value: true,
              },
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
            {
              Header: "N° Consultation",
              accessor: "reference",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "110px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/consultations/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Nom",
              accessor: "nom_Consultation",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/consultations/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Statut",
              accessor: "statut",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              valueFieldToDisplay: "designation",
              service: StatutsConsultationService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Produit interne",
              accessor: "produit_Interne",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              valueFieldToDisplay: "designation",
              service: ProduitInterneService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Client",
              accessor: "client",
              search: "nom_Client",
              hideable: true,
              exportable: true,
              minWidth: "200px",
              type: "text",
              Cell: (row) => {
                if (row.cell.row.original.client) {
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/clients/"
                      id={row.cell.row.original.client.id}
                      value={row.cell.value ? row.cell.value.nom_Client : null}
                    />
                  );
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Contact",
              accessor: "contact",
              search: "nom_Contact",
              hideable: true,
              exportable: true,
              minWidth: "200px",
              type: "text",
              Cell: (row) => {
                if (
                  row.cell.value &&
                  (row.cell.value.nom_Contact || row.cell.value.prenom_Contact)
                ) {
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/contacts/"
                      id={row.cell.row.original.contact.id}
                      value={
                        IfNullToText(row.cell.value.nom_Contact, "") +
                        " " +
                        IfNullToText(row.cell.value.prenom_Contact, "")
                      }
                    />
                  );
                }
                return null;
              },
            },
            {
              Header: "Source",
              accessor: "source",
              hideable: true,
              // sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: SourceService.getAll,
              search: "idSource",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "International",
              accessor: "international",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
            {
              Header: "Date de création",
              accessor: "date_Creation",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Chargé d'affaires",
              accessor: "commercial",
              hideable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              valueFieldToDisplay: ["nom", "prenom"],
              optionFieldToReturn: "id",
              optionFieldToDisplay: ["nom", "prenom"],
              service: PersonnelService.getForSelectSearch,
              search: "idCommercial",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>
                    {row.cell.value.nom} {row.cell.value.prenom}
                  </span>
                ) : null;
              },
            },

            {
              Header: "Date de réception",
              accessor: "date_Reception",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

BaseConsultation.propTypes = {
  history: PropTypes.object,
};

export { BaseConsultation };
