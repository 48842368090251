import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { WarningBar } from "_components";

import { Helmet } from "react-helmet";

import TabSelector from "_components/FicheComponents/TabSelector";
import { General } from "./Onglets/General";
import { Gestion } from "./Onglets/Gestion";
import { Score } from "./Onglets/Score";
import { FournisseurService } from "_services";
import { Contacts } from "./Onglets/Contacts";
import { Historique } from "./Onglets/Historique";
import { Statistiques } from "./Onglets/Statistiques";

const FicheFournisseur = (props) => {
  const tabs = [
    "Général",
    "Contacts",
    "Gestion",
    "Statistique",
    "Score",
    "Historique",
  ];

  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("Général");
  const [fournisseur, setFournisseur] = useState(null);
  const [editing, setEditing] = useState(false);
  const [reloadstate, setReloadState] = useState(false);

  useEffect(() => reload(), []);

  useEffect(() => {
    if (fournisseur != null) handleUpdate();
  }, [reloadstate]);

  const handleChange = (accessor, value) => {
    // Shallow property

    if (accessor == "fournisseur") {
      setFournisseur(value);
    } else {
      console.log(accessor, value);
      setFournisseur({
        ...fournisseur,
        [accessor]: value,
      });
    }

    if (
      accessor == "historiqueScope3" ||
      accessor == "datesQuestionnaire" ||
      accessor == "contrats"
    )
      setReloadState(!reloadstate);
  };

  const handleEditing = (editing) => {
    setEditing(editing);
  };

  const handleUpdate = () => {
    console.log(fournisseur);
    return FournisseurService.put(fournisseur)
      .then(() => reload())
      .catch((e) => console.log(e));
  };

  const reload = () => {
    let { id } = props.match.params;
    //setLoading(true);
    FournisseurService.getById(id).then((e) => {
      setFournisseur(e.data);
      setLoading(false);
    });
  };

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  const generateTabs = (tabs) => {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={active}
          onSelect={(tab) => {
            setActive(tab);
            sessionStorage.setItem("FicheFournisseurOnglet", tab);
          }}
          notAllowed={editing}
        />
      </div>
    );
  };

  const renderActive = () => {
    switch (active) {
      case "Général":
        return (
          <General
            fournisseur={fournisseur}
            handleChange={handleChange}
            handleEditing={handleEditing}
            service={FournisseurService}
            stateFieldNameToUpdate="fournisseur"
            handleUpdate={handleUpdate}
          />
        );
      case "Gestion":
        return (
          <Gestion
            fournisseur={fournisseur}
            handleChange={handleChange}
            handleEditing={handleEditing}
            service={FournisseurService}
            editing={editing}
            stateFieldNameToUpdate="fournisseur"
            handleUpdate={handleUpdate}
          />
        );
      case "Score":
        return (
          <Score
            fournisseur={fournisseur}
            handleChange={handleChange}
            handleEditing={handleEditing}
            service={FournisseurService}
            stateFieldNameToUpdate="fournisseur"
            handleUpdate={handleUpdate}
          />
        );
      case "Contacts":
        return (
          <Contacts
            fournisseur={fournisseur}
            handleChange={handleChange}
            handleEditing={handleEditing}
            service={FournisseurService}
            reload={reload}
            stateFieldNameToUpdate="fournisseur"
            handleUpdate={handleUpdate}
            history={props.history}
          />
        );
      case "Historique":
        return (
          <Historique
            fournisseur={fournisseur}
            handleChange={handleChange}
            handleEditing={handleEditing}
            service={FournisseurService}
            reload={reload}
            stateFieldNameToUpdate="fournisseur"
            handleUpdate={handleUpdate}
          />
        );
      case "Statistique":
        return (
          <Statistiques
            fournisseur={fournisseur}
            handleChange={handleChange}
            handleEditing={handleEditing}
            service={FournisseurService}
            reload={reload}
            stateFieldNameToUpdate="fournisseur"
            handleUpdate={handleUpdate}
          />
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Fournisseur : ".concat(fournisseur?.nom)}</title>
      </Helmet>

      {loading ? (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
        </div>
      ) : (
        <div className="p-3 m-4" id="ficheClient">
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h4 className="nowrap pr-100">
              {"Fournisseur : ".concat(fournisseur?.nom)}
            </h4>
            <div className="d-flex">
              {props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap ms-1"
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          {generateTabs(tabs)}
          <div
            style={{
              height: "50px",
              display: "flex",
              marginBottom: "20px",
            }}
          >
            <WarningBar
              active={true}
              content={"Cette licence n'est pas active"}
            />
          </div>
          {renderActive()}
        </div>
      )}
    </>
  );
};

export { FicheFournisseur };
