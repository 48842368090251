import {
  faExclamationTriangle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { AlertDialog, ButtonIconWithSmall, DialogForm } from "_components";
import { Input } from "_components/Input";
import {
  ChantierService,
  ContactService,
  MaterielsChantierService,
  PersonnelService,
  VehiculeService,
} from "_services";
import React, { useState, useEffect } from "react";
import { BlocChantier } from "./BlocChantier";
import moment from "moment";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const getArrayOfWeekByYear = (year) => {
  if (year == undefined) return [];
  let start = moment(new Date(year, 1, 1));
  let now = moment();

  let arrayWeeks = [];
  if (now.year() == start.year()) {
    for (let i = now.isoWeek(); i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  } else {
    for (let i = 1; i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  }
  return arrayWeeks;
};

const getArrayOfYears = () => {
  let now = moment();
  let year = now.year();
  let arrayYears = [];
  for (let i = 0; i < 3; i++) {
    arrayYears.push(year + i);
  }
  return arrayYears;
};

const ChantierContainer = (props) => {
  let [chantiers, setChantiers] = useState([]);
  let [newChantier, setNewChantier] = useState({
    techniciens: [],
    vehicules: [],
  });
  let [openForm, setOpenForm] = useState(() => {});

  let [forceUpdateWeek, setForceUpdateWeek] = useState(() => null);

  let [forceUpdateTechs, setForceUpdateTechs] = useState(() => null);
  let [forceUpdateVehicules, setForceUpdateVehicules] = useState(() => null);
  let [errorChantier, setErrorChantier] = useState(null);

  let [forceUpdateMateriels, setForceUpdateMateriels] = useState(() => null);

  let [changeAffected, setChangeAffected] = useState(0);

  useEffect(() => {
    if (forceUpdateTechs != null) {
      forceUpdateTechs();
      forceUpdateVehicules();
      forceUpdateMateriels();
    }
  }, [changeAffected]);

  const handleGetAll = () => {
    ChantierService.getByIdAffaire(props.affaire?.id).then((e) =>
      setChantiers(e.data)
    );
  };

  const updateOne = (id) => {
    ChantierService.put(chantiers.filter((e) => e.id == id)[0])
      .then(() => {
        handleGetAll();
      })
      .catch();
  };

  const duplicateNew = (idChantier) => {
    setNewChantier({});
    let index = chantiers.findIndex((e) => e.id == idChantier);
    let chantierToDuplicate = chantiers[index];
    let newchantiertmp = {
      anneeChantier: chantierToDuplicate.anneeChantier,
      planPrevention: chantierToDuplicate.planPrevention,
      labo: chantierToDuplicate.labo,
      nbNuitsPrev: chantierToDuplicate.nbNuitsPrev,
      nbJoursPrev: chantierToDuplicate.nbJoursPrev,
      nbSondagesPrev: chantierToDuplicate.nbSondagesPrev,
      techniciens: [...chantierToDuplicate.techniciens],
      vehicules: [...chantierToDuplicate.vehicules],
      preparateurs: [...chantierToDuplicate.preparateurs],
      materielsChantier: [...chantierToDuplicate.materielsChantier],
      lieuChantier: { ...chantierToDuplicate.lieuChantier },
      lieuRDV: chantierToDuplicate.lieuRDV,
      heureRDV: chantierToDuplicate.heureRDV,
      heureArriveeBureau: chantierToDuplicate.heureArriveeBureau,
      lienGPS: chantierToDuplicate.lienGPS,
    };

    setNewChantier(newchantiertmp);
    openForm();
  };

  const handleChangeNew = (accessor, value) => {
    let newChantierTmp = { ...newChantier };
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");
      // Nested property (separated by a '.')

      newChantierTmp = {
        ...newChantierTmp,
        [accessorSplit[0]]: {
          ...newChantierTmp[accessorSplit[0]],
          [accessorSplit[1]]: value,
        },
      };
    } else {
      newChantierTmp = { ...newChantierTmp, [accessor]: value };
    }

    if (accessor == "anneeChantier") {
      setNewChantier({ ...newChantierTmp, semaineChantier: null });
      forceUpdateWeek();
    } else if (
      accessor == "dateDebutChantier" ||
      accessor == "nbJoursPrev" ||
      accessor == "nbNuitsPrev" ||
      accessor == "heureRDV" ||
      accessor == "heureFinChantier"
    ) {
      setNewChantier(newChantierTmp);
      setChangeAffected(changeAffected + 1);
    } else {
      setNewChantier(newChantierTmp);
    }
  };

  const handleChangeUpdate = (accessor, value, id) => {
    let index = chantiers.findIndex((e) => e.id == id);
    let chantierToUpdate = chantiers[index];
    let chantierUpdated = { ...chantierToUpdate };
    const newArray = [...chantiers];
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");
      // Nested property (separated by a '.')

      chantierUpdated = {
        ...chantierUpdated,
        [accessorSplit[0]]: {
          ...chantierUpdated[accessorSplit[0]],
          [accessorSplit[1]]: value,
        },
      };
    } else {
      chantierUpdated = { ...chantierUpdated, [accessor]: value };
    }

    newArray[index] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleChangePeriodeUpdate = (
    accessor,
    value,
    idChantier,
    idPeriode
  ) => {
    let indexChantier = chantiers.findIndex((e) => e.id == idChantier);
    let chantierToUpdate = chantiers[indexChantier];
    let indexPeriode = chantierToUpdate.periodeChantiers.findIndex(
      (e) => e.id == idPeriode
    );
    let periodeToUpdate = chantierToUpdate.periodeChantiers[indexPeriode];
    let periodeUpdated = { ...periodeToUpdate, [accessor]: value };
    if (accessor == "periode" && value != null) {
      if (
        chantierToUpdate.semaineChantier != null &&
        chantierToUpdate.anneeChantier != null
      ) {
        if (indexPeriode == 0) {
          let date = moment().isoWeek(chantierToUpdate.semaineChantier);
          date.isoWeekday(periodeUpdated.periode?.position + 1);
          date.year(chantierToUpdate.anneeChantier);
          periodeUpdated.datePeriode = date.format("YYYY-MM-DD");
        } else {
          let periodePrec = chantierToUpdate.periodeChantiers[indexPeriode - 1];
          let date = moment(periodePrec.datePeriode);

          let diff =
            periodeUpdated.periode?.position - periodePrec.periode?.position;
          if (diff <= 0) {
            // si même periode
            diff += 7;
          }
          date.add(diff, "days");
          periodeUpdated.datePeriode = date.format("YYYY-MM-DD");
        }
      }
    }

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers[indexPeriode] = periodeUpdated;

    const newArray = [...chantiers];
    newArray[indexChantier] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleAddPeriode = (idChantier, periode) => {
    let indexChantier = chantiers.findIndex((e) => e.id == idChantier);
    let chantierToUpdate = chantiers[indexChantier];

    let arrayPeriode = [...chantierToUpdate.periodeChantiers, periode];

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers = arrayPeriode;

    const newArray = [...chantiers];
    newArray[indexChantier] = chantierUpdated;

    setChantiers(newArray);
  };

  const handleResetChantier = (idchantier, reset) => {
    let index = chantiers.findIndex((e) => e.id == idchantier);
    let chantierReseted = { ...reset };
    const newArray = [...chantiers];
    newArray[index] = chantierReseted;

    setChantiers(newArray);
  };

  const serviceGetContactsByClientId = () => {
    if (props.affaire.client.id) {
      return ContactService.getByIdClient(props.affaire.client.id);
    }
  };
  const setOpenDialog = (func) => {
    setOpenForm(() => func);
  };

  const getTechniciensProxy = (params) => {
    return PersonnelService.getTechniciens({
      ...params,
      date: newChantier.dateDebutChantier,
      nbJours: (newChantier.nbJoursPrev ?? 0) + (newChantier.nbNuitsPrev ?? 0),
    });
  };
  const getVehiculesProxy = (params) => {
    return VehiculeService.getAllPourChantier({
      ...params,
      date: newChantier.dateDebutChantier,
      nbJours: (newChantier.nbJoursPrev ?? 0) + (newChantier.nbNuitsPrev ?? 0),
      heureFinChantier: newChantier.heureFinChantier,
      heureRDV: newChantier.heureRDV,
    });
  };
  const getMaterielsProxy = (params) => {
    return MaterielsChantierService.getAllPourChantier({
      ...params,
      date: newChantier.dateDebutChantier,
      nbJours: (newChantier.nbJoursPrev ?? 0) + (newChantier.nbNuitsPrev ?? 0),
    });
  };

  const setErrorFromChantier = (error) => setErrorChantier(error);

  useEffect(() => {
    handleGetAll();
  }, []);

  let chantierHtml = chantiers?.map((e) => (
    <BlocChantier
      key={e.id}
      chantier={e}
      affaire={props.affaire}
      handleGetAll={handleGetAll}
      update={updateOne}
      handleChange={handleChangeUpdate}
      handleChangePeriode={handleChangePeriodeUpdate}
      handleAddPeriode={handleAddPeriode}
      handleResetChantier={handleResetChantier}
      duplicateNew={duplicateNew}
      setErrorFromChantier={setErrorFromChantier}
    ></BlocChantier>
  ));

  let dialogAddChantier = (
    <DialogForm
      tooltip="Ajouter un chantier"
      classNameButton="btn btn-success"
      dialogTitle="Ajouter un chantier"
      labelValidateButton="Valider"
      setOpenDialog={setOpenDialog}
      onValidate={() => {
        ChantierService.post({ ...newChantier, affaire: props.affaire }).then(
          () => {
            setNewChantier({});
            handleGetAll();
          }
        );
      }}
      onClose={() => {}}
      body={
        <div
          id="PopupCommande"
          className={"col-lg-12 row"}
          style={{ minWidth: "1000px" }}
        >
          <div className="row">
            <div className="col">
              <div>
                <Input
                  label="Année"
                  value={newChantier.anneeChantier}
                  accessor="anneeChantier"
                  handleBlur={handleChangeNew}
                  type="selectSearch"
                  options={getArrayOfYears()}
                  required
                />
              </div>
              <div>
                <Input
                  label="Semaine"
                  value={newChantier.semaineChantier}
                  accessor="semaineChantier"
                  handleBlur={handleChangeNew}
                  type="selectSearch"
                  options={getArrayOfWeekByYear(newChantier.anneeChantier)}
                  setForceUpdateFunction={(callback) =>
                    setForceUpdateWeek(() => callback)
                  }
                  required={true}
                />
              </div>
              <div>
                <Input
                  label="Date début chantier"
                  value={newChantier.dateDebutChantier}
                  accessor="dateDebutChantier"
                  handleBlur={handleChangeNew}
                  type="date"
                  required={true}
                />
              </div>
              <div>
                <Input
                  label="HeureRDV"
                  value={newChantier.heureRDV}
                  accessor="heureRDV"
                  handleChange={handleChangeNew}
                  type="time"
                />
              </div>
              <div>
                <Input
                  label="Lieu RDV"
                  value={newChantier.lieuRDV}
                  accessor="lieuRDV"
                  handleChange={handleChangeNew}
                  type="text"
                />
              </div>
              <div>
                <Input
                  label="Heure Arrivée bureau"
                  value={newChantier.heureArriveeBureau}
                  accessor="heureArriveeBureau"
                  handleChange={handleChangeNew}
                  type="time"
                />
              </div>
              <div>
                <Input
                  label="Heure Fin chantier"
                  value={newChantier.heureFinChantier}
                  accessor="heureFinChantier"
                  handleChange={handleChangeNew}
                  type="time"
                />
              </div>
            </div>

            <div className="col">
              <div>
                <Input
                  label="Contact"
                  value={newChantier.contact}
                  accessor="contact"
                  handleChange={handleChangeNew}
                  type="selectSearch"
                  service={serviceGetContactsByClientId}
                  optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                />
              </div>
              <div>
                <Input
                  label="PP"
                  value={newChantier.planPrevention}
                  accessor="planPrevention"
                  handleChange={handleChangeNew}
                  type="checkbox"
                />
              </div>

              <div>
                <Input
                  label="Labo"
                  value={newChantier.labo}
                  accessor="labo"
                  handleChange={handleChangeNew}
                  type="checkbox"
                />
              </div>
              <div>
                <Input
                  label="Lieu chantier"
                  value={newChantier.lieuChantier}
                  accessor="lieuChantier"
                  handleChange={handleChangeNew}
                  type="address"
                  intituleLabel="Lieu chantier"
                />
              </div>
              <div>
                <Input
                  label="Lien GPS"
                  value={newChantier.lienGPS}
                  accessor="lienGPS"
                  handleChange={handleChangeNew}
                  type="text"
                />
              </div>
            </div>

            <div className="col">
              <div>
                <Input
                  label="Nuits prévues"
                  value={newChantier.nbNuitsPrev}
                  accessor="nbNuitsPrev"
                  handleBlur={handleChangeNew}
                  type="decimal"
                  numberOfDecimals={2}
                />
              </div>
              <div>
                <Input
                  label="Jours prévus"
                  value={newChantier.nbJoursPrev}
                  accessor="nbJoursPrev"
                  handleBlur={handleChangeNew}
                  type="decimal"
                  numberOfDecimals={2}
                />
              </div>
              <div>
                <Input
                  label="Nombre de sondages prévus"
                  value={newChantier.nbSondagesPrev}
                  accessor="nbSondagesPrev"
                  handleBlur={handleChangeNew}
                  type="decimal"
                  numberOfDecimals={0}
                />
              </div>
            </div>
            <div className="col">
              <Input
                label="Techniciens"
                value={newChantier.techniciens}
                accessor="techniciens"
                handleChange={handleChangeNew}
                type="multipleSelectSearch"
                service={getTechniciensProxy}
                optionFieldToDisplay={["nom", "prenom"]}
                valueFieldToDisplay={["nom", "prenom"]}
                renderOption={(props, option, selected) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.occupe ? (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="me-2 text-danger"
                      />
                    ) : null}
                    {option.nom +
                      " " +
                      option.prenom +
                      " - " +
                      option.service?.sigle ?? ""}
                  </li>
                )}
                setForceUpdateFunction={(callback) =>
                  setForceUpdateTechs(() => callback)
                }
              />
              <Input
                label="Vehicules"
                value={newChantier.vehicules}
                accessor="vehicules"
                handleChange={handleChangeNew}
                type="multipleSelectSearch"
                service={getVehiculesProxy}
                optionFieldToDisplay={[
                  "designation",
                  "nom",
                  "produitInterne.designation",
                ]}
                valueFieldToDisplay={[
                  "designation",
                  "nom",
                  "produitInterne.designation",
                ]}
                renderOption={(props, option, selected) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.occupe ? (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="me-2 text-danger"
                      />
                    ) : null}

                    {option.designation +
                      " - " +
                      (option.produitInterne?.designation ?? "") +
                      " (" +
                      option.nom +
                      ")"}
                  </li>
                )}
                setForceUpdateFunction={(callback) =>
                  setForceUpdateVehicules(() => callback)
                }
              />
              <div>
                <Input
                  label="Preparateurs"
                  value={newChantier.preparateurs}
                  accessor="preparateurs"
                  handleChange={handleChangeNew}
                  type="multipleSelectSearch"
                  service={PersonnelService.getAll}
                  optionFieldToDisplay={["nom", "prenom"]}
                  valueFieldToDisplay={["nom", "prenom"]}
                />
              </div>
              <div>
                <Input
                  label="Matériels"
                  value={newChantier.materielsChantier}
                  accessor="materielsChantier"
                  handleChange={handleChangeNew}
                  type="multipleSelectSearch"
                  service={getMaterielsProxy}
                  optionFieldToDisplay={[
                    "designation",
                    "num_Serie",
                    "produitInterne.designation",
                  ]}
                  valueFieldToDisplay={[
                    "designation",
                    "num_Serie",
                    "produitInterne.designation",
                  ]}
                  renderOption={(props, option, selected) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.occupe ? (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="me-2 text-danger"
                        />
                      ) : null}
                      {(option.produitInterne?.designation ?? "") +
                        " | " +
                        option.designation +
                        (option.num_Serie ? " (" + option.num_Serie + ")" : "")}
                    </li>
                  )}
                  setForceUpdateFunction={(callback) =>
                    setForceUpdateMateriels(() => callback)
                  }
                />
              </div>
              <div>
                <Input
                  label="Commentaire"
                  value={newChantier.commentaire}
                  accessor="commentaire"
                  handleChange={handleChangeNew}
                  type="textArea"
                />
              </div>
            </div>

            <div className="col">
              <Input
                label="Zone réservation"
                value={newChantier.zoneReservation}
                accessor="zoneReservation"
                handleChange={handleChangeNew}
                type="text"
              />
              <Input
                label="Période réservation"
                value={newChantier.trancheDateReservation}
                accessor="trancheDateReservation"
                handleChange={handleChangeNew}
                type="period"
              />
              <Input
                label="Proposition hôtel"
                value={newChantier.propositionHotel}
                accessor="propositionHotel"
                handleChange={handleChangeNew}
                type="text"
              />
              <Input
                label="Petit déjeuner"
                value={newChantier.petitDej}
                accessor="petitDej"
                handleChange={handleChangeNew}
                type="checkbox"
              />
              <Input
                label="Soirée étape"
                value={newChantier.soireeEtape}
                accessor="soireeEtape"
                handleChange={handleChangeNew}
                type="checkbox"
              />
              <Input
                label="Parking"
                value={newChantier.parking}
                accessor="parking"
                handleChange={handleChangeNew}
                type="checkbox"
              />
              <Input
                label="Commentaire"
                value={newChantier.commentaireHotel}
                accessor="commentaireHotel"
                handleChange={handleChangeNew}
                type="textArea"
              />
            </div>
          </div>
        </div>
      }
    />
  );
  return (
    <>
      <div
        className={"scrollPetitEcran row tableauBodyInformations bg-white "}
        style={{
          flexGrow: 1,
          border: "1px solid black",
          placeContent: "flex-start",
          borderRadius: "15px",
          textAlign: "center",
          padding: "10px",
          //display: "none",
        }}
      >
        <ButtonIconWithSmall
          icon={faPlus}
          className="btn btn-success text-light ms-1"
          onClick={() => openForm()}
        />
      </div>
      <AlertDialog
        title="Une erreur a été rencontrée"
        body={
          <>
            <div>
              Une erreur est survenue lors de la mise à jour des données :
            </div>
            <div>{errorChantier}</div>
          </>
        }
        valider="Ok"
        open={errorChantier}
        handleClose={() => setErrorChantier(null)}
      />
      {dialogAddChantier}
      {chantierHtml}
    </>
  );
};

export { ChantierContainer };
