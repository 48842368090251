import React from "react";

import { App } from "App";

import { PropTypes } from "prop-types";

import {
  Box,
  ButtonIcon,
  WarningBar,
  FormWithValidation,
  DialogFormHideable,
  AlertDialog,
} from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Input } from "_components/Input";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
  faAngleLeft,
  faAngleRight,
  faSort,
  faSortUp,
  faSortDown,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { LigneHeure } from "./LigneHeure";
import { LigneNewHeure } from "./LigneNewHeure";

import moment from "moment";
import { produce } from "immer";

import "./Heures.css";
import { HeurePersonnelRubriqueService, JoursFeriesService } from "_services";
import { HeuresService } from "_services/HeuresService";
import { dateIsValid } from "_utils";

class Heures extends React.PureComponent {
  constructor(props) {
    super(props);
    let dateStart = new Date();
    dateStart.setDate(new Date().getDate() - 30);
    this.state = {
      savedHeures: null,
      filterByMonth: false,
      filterByWeek: true,
      filterByPeriod: false,
      sortBy: "date",
      searchDesc: true,
      dateFiltreSemaineAffichees: new Date(),
      dateFiltreMoisAffichees: new Date(),
      dateFiltrePeriodAffichees:
        dateStart.toISOString() + "|" + new Date().toISOString(),

      newHeures: [],
      heures: [],
      modified: [],

      loadingHeure: true,

      referentielRubriquesHeuresPersonnel: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.addLineNewHeurePersonnel = this.addLineNewHeurePersonnel.bind(this);
    this.deleteLineNewHeurePersonnel =
      this.deleteLineNewHeurePersonnel.bind(this);
    this.deleteLineHeurePersonnel = this.deleteLineHeurePersonnel.bind(this);
    this.clearNewHeuresPersonnel = this.clearNewHeuresPersonnel.bind(this);
    this.submitNewHeuresPersonnel = this.submitNewHeuresPersonnel.bind(this);
    this.copierLigneHeure = this.copierLigneHeure.bind(this);
    this.collerLigneHeure = this.collerLigneHeure.bind(this);
    this.getHeures = this.getHeures.bind(this);
    this.handleUpdateHeure = this.handleUpdateHeure.bind(this);
    this.filtrerByPeriod = this.filtrerByPeriod.bind(this);
    this.filtrerSelonDate = this.filtrerSelonDate.bind(this);
    this.filtrerSelonMois = this.filtrerSelonMois.bind(this);
    this.filtrerSelonSemaine = this.filtrerSelonSemaine.bind(this);
    this.changeDateMois = this.changeDateMois.bind(this);
    this.changeDateWeek = this.changeDateWeek.bind(this);
    this.generateDialogForms = this.generateDialogForms.bind(this);
    this.handleClickEditionHeuresPersonnel =
      this.handleClickEditionHeuresPersonnel.bind(this);
    this.handleClickUndoHeuresPersonnel =
      this.handleClickUndoHeuresPersonnel.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.setOpenDialogAjoutHeures = this.setOpenDialogAjoutHeures.bind(this);
  }

  componentDidMount() {
    HeuresService.getAll({
      personnel: this.props.personnel.id,
      intervalle: this.state.filterByPeriod,
      intervalleDate: this.state.dateFiltrePeriodAffichees,
      mois: this.state.filterByMonth,
      moisDate: this.state.dateFiltreMoisAffichees,
      semaine: this.state.filterByWeek,
      semaineDate: this.state.dateFiltreSemaineAffichees,
      rubrique: this.state.rubriqueId,
    }).then((res) =>
      this.setState({ heures: res.data.heures, loadingHeure: false })
    );

    HeurePersonnelRubriqueService.getAll().then((res) => {
      this.setState({ referentielRubriquesHeuresPersonnel: res.data }, () => {
        JoursFeriesService.getAllByPeriod({
          start: "2024-01-01",
          end: "2024-12-31",
        }).then((resferie) => {
          let jf = resferie.data;
          if (this.props.chantier != null) {
            let rubriqueJ =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "CHANT"
              );
            let rubriqueSam =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "CHSAM"
              );
            let rubriqueNtSam =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "NTSAM"
              );
            let rubriqueDim =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "CHDIM"
              );
            let rubriqueNtDim =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "NTDIM"
              );
            let rubriqueBureau =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "BUREA"
              );
            let rubriqueFer =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "CHFER"
              );
            let rubriqueNtFer =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "NTFER"
              );
            let rubriqueN =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "NUIT"
              );

            let rubriqueAR =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "ARCHA"
              );

            let rubriqueRepas =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "REPAS"
              );

            let rubriqueDepla =
              this.state.referentielRubriquesHeuresPersonnel?.find(
                (e) => e.code == "DEPLA"
              );

            this.setState(
              produce((prevState) => {
                prevState.editing = true;
                prevState.editingHeuresPersonnel = true;
                let chantierIsEndo =
                  this.props.chantier?.affaire?.produitInterne?.designation ==
                    "ENDO" ||
                  this.props.chantier?.affaire?.produitInterne?.designation ==
                    "DO" ||
                  this.props.chantier?.affaire?.produitInterne?.designation ==
                    "ETU+ENDO";

                this.props.chantier?.periodeChantiers?.map((e, index) => {
                  let tech = e.techniciensPresents?.map((t) => t.id);
                  if (tech.indexOf(Number(this.props.personnel.id)) > -1) {
                    let isFerie = false;
                    jf.map(
                      (j) => (isFerie = j.jour == e.datePeriode || isFerie)
                    );
                    let rubriqueToUse = rubriqueJ;
                    if (isFerie) {
                      if (e.periode.isNuit) {
                        rubriqueToUse = rubriqueNtFer;
                      } else rubriqueToUse = rubriqueFer;
                    } else if (e.periode.designation == "Samedi") {
                      rubriqueToUse = rubriqueSam;
                    } else if (e.periode.designation == "Dimanche") {
                      rubriqueToUse = rubriqueDim;
                    } else if (e.periode.designation == "DiLu") {
                      rubriqueToUse = rubriqueNtDim;
                    } else if (e.periode.designation == "SaDi") {
                      rubriqueToUse = rubriqueNtSam;
                    } else if (e.periode.isNuit) {
                      rubriqueToUse = rubriqueN;
                    } else {
                      rubriqueToUse = rubriqueJ;
                    }
                    let heureChantier = {
                      id: -index * 3 - 1,
                      rubrique: rubriqueToUse,
                      date: e.datePeriode,
                      affaire: this.props.chantier?.affaire,
                      chantier: this.props.chantier,
                      nombre: e.tempsChantier,
                      tauxHoraire: null,
                      codePoste: "",
                      v: false,
                      c1: false,
                      created: true,
                    };
                    prevState.newHeures.push(heureChantier);

                    if (
                      e.tempsBureauEtMateriel != null &&
                      e.tempsBureauEtMateriel != 0
                    ) {
                      let heureBureau = {
                        id: -index * 3 - 2,
                        rubrique: rubriqueBureau,
                        date: e.datePeriode,
                        affaire: this.props.chantier?.affaire,
                        chantier: this.props.chantier,
                        nombre: e.tempsBureauEtMateriel,
                        tauxHoraire: null,
                        codePoste: "",
                        v: false,
                        c1: false,
                        created: true,
                      };
                      prevState.newHeures.push(heureBureau);
                    }

                    if (
                      e.repasRealises != null &&
                      e.repasRealises != 0 &&
                      this.props.personnel.statut?.designation == "ETAM"
                    ) {
                      let heureRepas = {
                        id: -index * 3 - 1000,
                        rubrique: rubriqueRepas,
                        date: e.datePeriode,
                        affaire: this.props.chantier?.affaire,
                        chantier: this.props.chantier,
                        nombre: e.repasRealises,
                        tauxHoraire: null,
                        codePoste: "",
                        v: false,
                        c1: false,
                        created: true,
                      };
                      prevState.newHeures.push(heureRepas);
                    }

                    if (e.tempsDeplacement != null) {
                      //if est un chantier endo
                      if (chantierIsEndo) {
                        if (isFerie) {
                          let heureAr = {
                            id: -index * 3 - 3,
                            rubrique: rubriqueFer,
                            date: e.datePeriode,
                            affaire: this.props.chantier?.affaire,
                            chantier: this.props.chantier,
                            nombre: e.tempsDeplacement,
                            tauxHoraire: null,
                            codePoste: "",
                            v: false,
                            c1: false,
                            created: true,
                          };
                          prevState.newHeures.push(heureAr);
                        } else if (
                          e.periode.designation == "Dimanche" ||
                          e.periode.designation == "Samedi" ||
                          (index != 0 &&
                            index !=
                              this.props.chantier.periodeChantiers.length - 1)
                        ) {
                          heureChantier.nombre += e.tempsDeplacement ?? 0;
                        } else if (
                          e.periode.designation == "SaDi" &&
                          (index == 0 ||
                            index ==
                              this.props.chantier.periodeChantiers.length - 1)
                        ) {
                          let date = moment(e.datePeriode).add(1, "days");
                          let heureAr = {
                            id: -index * 3 - 3,
                            rubrique: rubriqueDim,
                            date: date,
                            affaire: this.props.chantier?.affaire,
                            chantier: this.props.chantier,
                            nombre: e.tempsDeplacement,
                            tauxHoraire: null,
                            codePoste: "",
                            v: false,
                            c1: false,
                            created: true,
                          };
                          prevState.newHeures.push(heureAr);
                        } else {
                          let heureAr = {
                            id: -index * 3 - 3,
                            rubrique: rubriqueAR,
                            date: e.datePeriode,
                            affaire: this.props.chantier?.affaire,
                            chantier: this.props.chantier,
                            nombre: e.tempsDeplacement,
                            tauxHoraire: null,
                            codePoste: "",
                            v: false,
                            c1: false,
                            created: true,
                          };
                          prevState.newHeures.push(heureAr);
                        }
                      }
                      // est un chantier pas endo
                      else {
                        if (isFerie) {
                          let heureAr = {
                            id: -index * 3 - 3,
                            rubrique: rubriqueFer,
                            date: e.datePeriode,
                            affaire: this.props.chantier?.affaire,
                            chantier: this.props.chantier,
                            nombre: e.tempsDeplacement,
                            tauxHoraire: null,
                            codePoste: "",
                            v: false,
                            c1: false,
                            created: true,
                          };
                          prevState.newHeures.push(heureAr);
                        } else if (
                          e.periode.designation == "Dimanche" ||
                          e.periode.designation == "Samedi" ||
                          (e.isDeplaNuit && e.isDeplaNuit == e.periode.isNuit)
                        ) {
                          heureChantier.nombre += e.tempsDeplacement ?? 0;
                        } else if (
                          e.isDeplaNuit &&
                          e.isDeplaNuit != e.periode.isNuit
                        ) {
                          let heureAr = {
                            id: -index * 3 - 3,
                            rubrique: rubriqueN,
                            date: e.datePeriode,
                            affaire: this.props.chantier?.affaire,
                            chantier: this.props.chantier,
                            nombre: e.tempsDeplacement,
                            tauxHoraire: null,
                            codePoste: "",
                            v: false,
                            c1: false,
                            created: true,
                          };
                          prevState.newHeures.push(heureAr);
                        } else {
                          let heureAr = {
                            id: -index * 3 - 3,
                            rubrique: rubriqueAR,
                            date: e.datePeriode,
                            affaire: this.props.chantier?.affaire,
                            chantier: this.props.chantier,
                            nombre: e.tempsDeplacement,
                            tauxHoraire: null,
                            codePoste: "",
                            v: false,
                            c1: false,
                            created: true,
                          };
                          prevState.newHeures.push(heureAr);
                        }
                      }
                    }

                    if (
                      this.props.chantier.dateReservationDebut != null &&
                      this.props.chantier.dateReservationFin != null &&
                      this.props.personnel.fonction?.designation !=
                        "Responsable de pôle" &&
                      this.props.personnel.fonction?.designation !=
                        "Responsable de Service"
                    ) {
                      let b1 = moment(this.props.chantier.dateReservationDebut);
                      let b2 = moment(
                        this.props.chantier.dateReservationFin
                      ).add(-1, "days");

                      let encours = moment(e.datePeriode);

                      if (b1 <= encours && encours < b2) {
                        var i = -10000 - index;

                        prevState.newHeures.push({
                          id: i,
                          rubrique: rubriqueDepla,
                          date: e.datePeriode,
                          affaire: this.props.chantier?.affaire,
                          chantier: this.props.chantier,
                          tauxHoraire: null,
                          codePoste: "",
                          nombre: 1,
                          v: false,
                          c1: false,
                          created: true,
                        });
                      }
                    }
                  }
                });
              }),
              this.state.dialog
            );
          }
        });
      });
    });
  }

  handleChange(accessor, value, callbackFunction) {
    if (accessor.includes(".")) {
      // Nested property
      var accessorSplit = accessor.split(".");

      if (accessorSplit[0] === "heurePersonnel") {
        // Format heurePersonnel.id.accessor
        var heureId = accessorSplit[1];
        var heureAccessor = accessorSplit[2];

        if (heureAccessor === "rubrique") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.heures.find((el) => el.id == heureId);
              let prevmodified = prevState.modified.find(
                (el) => el.id == heureId
              );
              prev.rubrique = value;
              prev.tauxHoraire = null;
              prev.updated = true;
              if (value?.nombreHeuresDefaut != null) {
                prev.nombre = value.nombreHeuresDefaut;
              }

              if (prevmodified != undefined) {
                prevmodified.rubrique = value;
                prevmodified.tauxHoraire = null;
                prevmodified.updated = true;
                if (value?.nombreHeuresDefaut != null) {
                  prevmodified.nombre = value.nombreHeuresDefaut;
                }
              } else {
                prevState.modified.push(prev);
              }
            }),
            callbackFunction
          );
        } else {
          this.setState(
            produce((prevState) => {
              let prev = prevState.heures.find((el) => el.id == heureId);
              prev[heureAccessor] = value;
              prev.updated = true;

              let prevmodified = prevState.modified.find(
                (el) => el.id == heureId
              );

              if (prevmodified != undefined) {
                prevmodified[heureAccessor] = value;
              } else {
                prevState.modified.push(prev);
              }
            }),
            callbackFunction
          );
        }
      } else if (accessorSplit[0] === "newHeurePersonnel") {
        // Format newHeurePersonnel.id.accessor
        var heureId = accessorSplit[1];
        var heureAccessor = accessorSplit[2];

        if (heureAccessor === "rubrique") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.newHeures.find((el) => el.id == heureId);

              prev.rubrique = value;
              prev.tauxHoraire = null;
              if (value?.nombreHeuresDefaut != null) {
                prev.nombre = value.nombreHeuresDefaut;
              }
            }),
            callbackFunction
          );
        } else {
          this.setState(
            produce((prevState) => {
              let prev = prevState.newHeures.find((el) => el.id == heureId);
              prev[heureAccessor] = value;
            }),
            callbackFunction
          );
        }
      } else if (accessorSplit[0] === "heurePersonnelLiee") {
        // Format heurePersonnel.id.accessor
        var heureId = accessorSplit[1];
        var heureAccessor = accessorSplit[2];

        if (heureAccessor === "rubrique") {
          this.setState(
            produce((prevState) => {
              let prev = prevState.heures.find((el) => el.id == heureId);

              prev.heureLiee.rubrique = value;
              prev.heureLiee.tauxHoraire = null;
              prev.updated = true;

              if (value?.nombreHeuresDefaut != null) {
                prev.heureLiee.nombre = value.nombreHeuresDefaut;
              }

              let prevmodified = prevState.modified.find(
                (el) => el.id == heureId
              );

              if (prevmodified != undefined) {
                prevmodified.heureLiee.rubrique = value;
                prevmodified.heureLiee.tauxHoraire = null;
                prevmodified.updated = true;
                if (value?.nombreHeuresDefaut != null) {
                  prevmodified.heureLiee.nombre = value.nombreHeuresDefaut;
                }
              } else {
                prevState.modified.push(prev);
              }
            }),
            callbackFunction
          );
        } else {
          this.setState(
            produce((prevState) => {
              let prev = prevState.heures.find((el) => el.id == heureId);
              prev.heureLiee[heureAccessor] = value;
              prev.updated = true;

              let prevmodified = prevState.modified.find(
                (el) => el.id == heureId
              );

              if (prevmodified != undefined) {
                prevmodified.heureLiee[heureAccessor] = value;
              } else {
                prevState.modified.push(prev);
              }
            }),
            callbackFunction
          );
        }
      }
    } else {
      //Update
      if (accessor == "selectAllRH") {
        this.setState({ selectAllRH: value }, () =>
          this.handleSelectAll(accessor)
        );
      } else if (accessor == "selectAllRESP") {
        this.setState({ selectAllRESP: value }, () =>
          this.handleSelectAll(accessor)
        );
      } else if (accessor == "rubriqueId") {
        this.setState({ rubriqueId: value, loadingHeure: true }, () => {
          this.getHeures();
        });
      } else if (accessor == "heures") {
        this.setState(
          {
            ["heures"]: value,
          },
          callbackFunction
        );
      }
    }
  }

  handleSelectAll(accessor) {
    let valueRH = this.state.selectAllRH;
    let valueRESP = this.state.selectAllRESP;
    this.state.heures
      ?.slice()
      ?.filter((h) => {
        if (this.state.rubriqueId != null) {
          return h.rubrique?.id == this.state.rubriqueId;
        } else {
          return true;
        }
      })
      ?.filter((h) => {
        if (this.state.filterByWeek && this.state.dateFiltreSemaineAffichees) {
          let mh = moment(h.date);
          let mf = moment(this.state.dateFiltreSemaineAffichees);
          return (
            h.date &&
            mh.isoWeek() == mf.isoWeek() &&
            mh.isoWeekYear() == mf.isoWeekYear()
          );
        }
        if (this.state.filterByMonth && this.state.dateFiltreMoisAffichees) {
          let mh = moment(h.date);
          let mf = moment(this.state.dateFiltreMoisAffichees);
          return (
            h.date &&
            mh.month() == mf.month() &&
            mh.isoWeekYear() == mf.isoWeekYear()
          );
        }

        if (this.state.filterByPeriod && this.state.dateFiltrePeriodAffichees) {
          let hdate = new Date(h.date);
          let split = this.state.dateFiltrePeriodAffichees.split("|");
          let dateStart = new Date(split[0]);
          dateStart?.setHours(0, 0, 0, 0);
          let dateEnd = new Date(split[1]);
          dateEnd?.setHours(23, 59, 59, 999);

          return (
            hdate &&
            (!dateIsValid(dateStart) || hdate >= dateStart) &&
            (!dateIsValid(dateEnd) || hdate <= dateEnd)
          );
        }

        return false;
      })
      .map((e) => {
        this.setState(
          produce((prevState) => {
            let value;
            let prev = prevState.heures.find((el) => el.id == e.id);
            prev.updated = true;
            if (accessor == "selectAllRH") {
              value = valueRH;
              if (prev.validationResponsable) {
                prev.validationRH = valueRH;
                if (prev.heureLiee) {
                  prev.heureLiee.validationRH = valueRH;
                }
              }
            } else {
              value = valueRESP;
              if (!prev.validationRH) {
                prev.validationResponsable = valueRESP;
                if (prev.heureLiee) {
                  prev.heureLiee.validationResponsable = valueRESP;
                }
              }
            }

            let prevmodified = prevState.modified.find((el) => el.id == e.id);

            if (prevmodified != undefined) {
              if (accessor == "selectAllRH") {
                prevmodified.validationRH = value;
                if (prevmodified.heureLiee != null)
                  prevmodified.heureLiee.validationRH = value;
              } else {
                prevmodified.validationResponsable = value;
                if (prevmodified.heureLiee != null)
                  prevmodified.heureLiee.validationResponsable = value;
              }
            } else {
              prevState.modified.push(prev);
            }
          })
        );
      });
  }

  addLineNewHeurePersonnel(codeRubrique) {
    let rubrique = codeRubrique
      ? this.state.referentielRubriquesHeuresPersonnel.find(
          (el) => el.code === codeRubrique
        )
      : null;

    this.setState(
      produce((prevState) => {
        if (prevState.newHeures == null) {
          prevState.newHeures = [];
        }
        if (prevState.heures == null) {
          prevState.heures = [];
        }
        // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
        let newLineId =
          Math.min(
            ...prevState.newHeures?.map((el) => el.id),
            ...prevState.heures?.map((el) => el.id),
            0
          ) - 1;

        prevState.newHeures.push({
          id: newLineId,
          rubrique: rubrique,
          date: new Date(),
          libelle: "",
          affaire: null,
          nombre: rubrique?.nombreHeuresDefaut,
          tauxHoraire: null,
          codePoste: "",
          v: false,
          c1: false,
          created: true,
        });
      })
    );
  }

  deleteLineNewHeurePersonnel(heureId) {
    this.setState(
      produce((prevState) => {
        prevState.newHeures.splice(
          prevState.newHeures.findIndex((el) => el.id === heureId),
          1
        );
      })
    );
  }

  deleteLineHeurePersonnel(heureId) {
    this.setState(
      produce((prevState) => {
        let prev = prevState.heures.find((el) => el.id == heureId);
        let prevmodified = prevState.modified.find((el) => el.id == heureId);

        prev.deleted = true;

        if (prevmodified != undefined) {
          prevmodified.deleted = true;
        } else {
          prevState.modified.push(prev);
        }
      })
    );
  }

  clearNewHeuresPersonnel() {
    this.setState(
      produce((prevState) => {
        prevState.newHeures = [];
      })
    );
  }

  submitNewHeuresPersonnel() {
    HeuresService.post({
      personnel: this.props.personnel,
      heures: this.state.newHeures,
    })
      .then((res1) => {
        this.props.handleResetChantier();
        this.setState(
          { modified: [], loadingHeure: true, message: res1.data.message },
          () => this.getHeures()
        );
        HeuresService.getAll({
          personnel: this.props.personnel.id,
          intervalle: this.state.filterByPeriod,
          intervalleDate: this.state.dateFiltrePeriodAffichees,
          mois: this.state.filterByMonth,
          moisDate: this.state.dateFiltreMoisAffichees,
          semaine: this.state.filterByWeek,
          semaineDate: this.state.dateFiltreSemaineAffichees,
          rubrique: this.state.rubriqueId,
        }).then((res) =>
          this.setState({ heures: res.data.heures }, () => {
            App.Toaster?.current?.createToast({
              body: "Vos heures ont bien été ajoutées",
              header: "Succès",
              type: "success",
            });
            this.props.handleEditingHeuresPersonnel(false);
          })
        );
      })
      .catch((error) => {
        console.log(error);
        App.Toaster?.current?.createToast({
          body: "Mis à jour",
          header: "Echec",
          type: "failure",
        });
        this.setState({
          error: true,
          errorText: (
            <>
              <br />
              <div>L&apos;enregistrement a échoué.</div>
              <br />
              <div>Code : {error?.response?.data?.code ?? "UNKOWN"}</div>
              <div>Cause: {error?.response?.data?.cause ?? "UNKOWN"}</div>
            </>
          ),
        });
      });
    this.clearNewHeuresPersonnel();
  }

  copierLigneHeure(id, contexte) {
    this.setState(
      produce((prevState) => {
        var aCopier = null;
        if (contexte == "creation")
          aCopier = prevState.newHeures.find((el) => el.id == id);
        else aCopier = prevState.heures.find((el) => el.id == id);

        prevState.lineACopier = aCopier;
      })
    );
  }

  collerLigneHeure(id, contexte) {
    this.setState(
      produce((prevState) => {
        if (prevState.lineACopier != null) {
          var aChanger = null;
          var alreadymodified = null;
          if (contexte == "creation") {
            aChanger = prevState.newHeures.find((el) => el.id == id);
          } else {
            aChanger = prevState.heures.find((el) => el.id == id);
            alreadymodified = prevState.modified.find((el) => el.id == id);
          }

          aChanger.date = prevState.lineACopier.date;
          aChanger.libelle = prevState.lineACopier.libelle;
          aChanger.affaire = prevState.lineACopier.affaire;
          aChanger.nombre = prevState.lineACopier.nombre;
          aChanger.codePoste = prevState.lineACopier.codePoste;
          aChanger.rubrique = prevState.lineACopier.rubrique;
          aChanger.updated = true;

          if (alreadymodified != null) {
            alreadymodified.date = prevState.lineACopier.date;
            alreadymodified.libelle = prevState.lineACopier.libelle;
            alreadymodified.affaire = prevState.lineACopier.affaire;
            alreadymodified.nombre = prevState.lineACopier.nombre;
            alreadymodified.codePoste = prevState.lineACopier.codePoste;
            alreadymodified.rubrique = prevState.lineACopier.rubrique;
          } else {
            prevState.modified.push(aChanger);
          }
        }
      })
    );
  }

  getHeures() {
    HeuresService.getAll({
      personnel: this.props.personnel.id,
      intervalle: this.state.filterByPeriod,
      intervalleDate: this.state.dateFiltrePeriodAffichees,
      mois: this.state.filterByMonth,
      moisDate: this.state.dateFiltreMoisAffichees,
      semaine: this.state.filterByWeek,
      semaineDate: this.state.dateFiltreSemaineAffichees,
      rubrique: this.state.rubriqueId,
    }).then((res) => {
      let idRes = res.data.heures.map((e) => e.id);

      let modifedLoad = this.state.modified
        ?.slice()
        .filter((e) => idRes.includes(e.id));
      let idMod = modifedLoad.map((e) => e.id);

      let toLoad = res.data.heures.filter((e) => !idMod.includes(e.id));
      let newHeures = toLoad.concat(modifedLoad);

      this.setState({ heures: newHeures, loadingHeure: false });
    });
  }

  handleUpdateHeure() {
    return HeuresService.put({
      personnel: this.props.personnel,
      heures: this.state.modified,
    })
      .then((res) => {
        this.setState(
          { modified: [], loadingHeure: true, message: res.data.message },
          () => this.getHeures()
        );
      })
      .catch((error) => {
        console.log(error);
        App.Toaster?.current?.createToast({
          body: "Mis à jour",
          header: "Echec",
          type: "failure",
        });
        this.setState({
          error: true,
          errorText: (
            <>
              <br />
              <div>L&apos;enregistrement a échoué.</div>
              <br />
              <div>Code : {error?.response?.data?.code ?? "UNKOWN"}</div>
              <div>Cause: {error?.response?.data?.cause ?? "UNKOWN"}</div>
            </>
          ),
        });
      });
  }

  filtrerSelonSemaine(acc, bool) {
    if (this.state.filterByWeek != bool) {
      this.setState(
        {
          filterByWeek: bool,
          filterByMonth: false,
          filterByPeriod: false,
          loadingHeure: true,
          selectAllRESP: false,
          selectAllRH: false,
        },
        () => {
          this.getHeures();
        }
      );
    }
  }

  changeDateWeek(value) {
    if (value > 0)
      this.setState(
        {
          dateFiltreSemaineAffichees: moment(
            this.state.dateFiltreSemaineAffichees
          ).add(7, "days"),
          loadingHeure: true,
          selectAllRESP: false,
          selectAllRH: false,
        },
        () => {
          this.getHeures();
        }
      );
    else
      this.setState(
        {
          dateFiltreSemaineAffichees: moment(
            this.state.dateFiltreSemaineAffichees
          ).subtract(7, "days"),
          loadingHeure: true,
          selectAllRESP: false,
          selectAllRH: false,
        },
        () => {
          this.getHeures();
        }
      );
  }

  filtrerSelonMois(acc, bool) {
    if (this.state.filterByMonth != bool) {
      this.setState(
        {
          filterByMonth: bool,

          filterByWeek: false,
          filterByPeriod: false,
          loadingHeure: true,
          selectAllRESP: false,
          selectAllRH: false,
        },
        () => {
          this.getHeures();
        }
      );
    }
  }

  changeDateMois(value) {
    if (value > 0)
      this.setState(
        {
          dateFiltreMoisAffichees: moment(this.state.dateFiltreMoisAffichees)
            .add(1, "months")
            .toDate(),
          loadingHeure: true,
          selectAllRESP: false,
          selectAllRH: false,
        },
        () => {
          this.getHeures();
        }
      );
    else
      this.setState(
        {
          dateFiltreMoisAffichees: moment(this.state.dateFiltreMoisAffichees)
            .subtract(1, "months")
            .toDate(),
          loadingHeure: true,
          selectAllRESP: false,
          selectAllRH: false,
        },
        () => {
          this.getHeures();
        }
      );
  }

  filtrerByPeriod(acc, bool) {
    this.setState(
      {
        filterByMonth: false,
        filterByWeek: false,
        filterByPeriod: bool,
        loadingHeure: true,
        selectAllRESP: false,
        selectAllRH: false,
      },
      () => {
        this.getHeures();
      }
    );
  }

  filtrerSelonDate(acc, date) {
    // valeur period

    this.setState(
      {
        dateFiltrePeriodAffichees: date,
        loadingHeure: true,
        selectAllRESP: false,
        selectAllRH: false,
      },
      () => {
        this.getHeures();
      }
    );
  }

  handleClickEditionHeuresPersonnel() {
    this.setState({
      savedHeures: [...this.state.heures],
    });
    this.props.handleEditingHeuresPersonnel(true);
  }

  handleClickUndoHeuresPersonnel() {
    this.handleChange("heures", this.state.savedHeures);
    this.props.handleEditingHeuresPersonnel(false);
  }

  onSubmitForm(event) {
    event?.preventDefault();
    this.handleUpdateHeure();
    this.props.handleEditingHeuresPersonnel(false);
  }

  setOpenDialogAjoutHeures(openDialogFunc) {
    this.setState(
      { openDialogAjoutHeuresFunc: openDialogFunc },
      this.props.chantier != null ? openDialogFunc() : () => {}
    );
  }

  getSortIcon(accessor) {
    let res = faSort;
    if (accessor == this.state.sortBy) {
      if (this.state.searchDesc) {
        res = faSortDown;
      } else {
        res = faSortUp;
      }
    }
    return res;
  }

  generateDialogForms() {
    return (
      <DialogFormHideable
        classNameButton="btn btn-success"
        tooltip={"Ajouter heure(s)"}
        dialogTitle={"Ajout de nouvelles heures"}
        labelCancelButton="Annuler"
        labelValidateButton="Ajouter"
        setOpenDialog={this.setOpenDialogAjoutHeures}
        onValidate={() => {
          this.submitNewHeuresPersonnel(
            document.getElementById("buttonValidateModifHeures")
          );
        }}
        onClose={this.clearNewHeuresPersonnel}
        fullScreen={true}
        body={
          <div
            id="PopupCommande"
            className={"col-lg-12 row"}
            style={{ minWidth: "1000px" }}
          >
            <table className="mt-1 text-uppercase heuresTable">
              <thead className="sticky-top bg-light">
                <tr>
                  <th className="maxWidth150">Rubrique</th>
                  <th>Compétence</th>

                  <th>Affaire</th>
                  <th>Libellé</th>
                  <th className="maxWidth130">Date</th>
                  <th className="maxWidth75">Nombre</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.newHeures?.map((heure) => (
                  <LigneNewHeure
                    key={heure.id}
                    heure={heure}
                    handleChange={this.handleChange}
                    deleteLineNewHeurePersonnel={
                      this.deleteLineNewHeurePersonnel
                    }
                    copierLigneHeure={this.copierLigneHeure}
                    collerLigneHeure={this.collerLigneHeure}
                  />
                ))}
                <tr>
                  <td>
                    <ButtonIcon
                      className="btn btn-success addHeure"
                      icon={faPlus}
                      iconSize="sm"
                      onClick={() => this.addLineNewHeurePersonnel?.()}
                      throttleOnClick={false}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      />
    );
  }

  render() {
    const isResponsable = App.RightsGuard?.current?.isResponsable();

    const isRH = App.RightsGuard?.current?.isRH();

    const isPersonne =
      App.RightsGuard?.current?.isPersonne(this.props.personnel.oid) == "RW";

    const rightVisibiliteCocheHeure = App.RightsGuard?.current?.hasRight(
      "Personnel",
      "VisibiliteCoches"
    );

    const rightCommentaireHeure = App.RightsGuard?.current?.hasRight(
      "Personnel",
      "CommentaireHeure"
    );

    const rightModifCocheResponsable = App.RightsGuard?.current?.hasRight(
      "Personnel",
      "Validation Heures Responsable"
    );

    const rightAddHeure = App.RightsGuard?.current?.hasRight(
      "Personnel",
      "Ajout Heures Autres Personnel"
    );

    if (isRH || isResponsable || isPersonne || rightAddHeure == "RW") {
      return (
        <>
          {this.generateDialogForms()}
          <AlertDialog
            title="Erreur"
            body={
              <>
                <div>Une erreur a été rencontrée lors de l&apos;opération.</div>
                <div>{this.state.errorText}</div>
              </>
            }
            valider="Ok"
            open={this.state.error}
            handleClose={() => this.setState({ error: false })}
          />
          <AlertDialog
            title="Information"
            body={
              <>
                <div>Résumé de l'opération.</div>
                <div>{this.state.message}</div>
              </>
            }
            valider="Ok"
            open={this.state.message != null && this.state.message != ""}
            handleClose={() => this.setState({ message: false })}
          />
          <div className="row">
            <div className="col-12 d-flex">
              <FormWithValidation
                className="d-flex flex-grow-1"
                onSubmit={this.onSubmitForm}
              >
                <Box
                  header={
                    <>
                      <div className="col-6 align-self-center py-2">
                        <div>Heures</div>
                      </div>
                      <div className="col-6 text-end py-2">
                        <ButtonIcon
                          icon={faPlus}
                          iconSize="sm"
                          onClick={this.state.openDialogAjoutHeuresFunc}
                          className="btn btn-success addHeure"
                          style={
                            !this.props.editingHeuresPersonnel
                              ? { display: "none" }
                              : {}
                          }
                        ></ButtonIcon>
                        <ButtonIcon
                          id="buttonValidateModifHeures"
                          icon={faCheck}
                          className="btn btn-success text-light ms-1"
                          style={
                            !this.props.editingHeuresPersonnel
                              ? { display: "none" }
                              : { width: "42px" }
                          }
                          type="submit"
                        ></ButtonIcon>
                        <ButtonIcon
                          icon={faTimes}
                          className="btn btn-danger text-light ms-1"
                          onClick={this.handleClickUndoHeuresPersonnel}
                          style={
                            !this.props.editingHeuresPersonnel
                              ? { display: "none" }
                              : { width: "42px" }
                          }
                        ></ButtonIcon>
                        <ButtonIcon
                          icon={faPen}
                          className="btn btn-secondary text-light ms-1"
                          onClick={this.handleClickEditionHeuresPersonnel}
                          style={
                            this.props.editing
                              ? { display: "none" }
                              : { width: "42px" }
                          }
                        ></ButtonIcon>
                      </div>
                    </>
                  }
                  body={
                    <>
                      <div className="col-3 align-self-center py-2">
                        Filtrer de date à date :
                        <div className="d-flex flex-row">
                          <div className="me-2">
                            <Input
                              value={this.state.filterByPeriod}
                              type="checkbox"
                              handleChange={this.filtrerByPeriod}
                            />
                          </div>
                          <Input
                            value={this.state.dateFiltrePeriodAffichees}
                            type="period"
                            handleChange={this.filtrerSelonDate}
                          />
                        </div>
                      </div>
                      <div className="col-2 align-self-center py-2">
                        Filtrer selon la semaine du :
                        <div className="d-flex flex-row">
                          <Input
                            value={this.state.filterByWeek}
                            type="checkbox"
                            handleChange={this.filtrerSelonSemaine}
                          />
                          <div className="d-flex flex-row">
                            <ButtonIcon
                              icon={faAngleLeft}
                              className="btn"
                              onClick={() => {
                                this.filtrerSelonSemaine(null, true);
                                this.changeDateWeek(-1);
                                this.setState({
                                  selectAllRESP: false,
                                  selectAllRH: false,
                                });
                              }}
                              style={{
                                paddingLeft: "6px",
                                paddingBottom: "6px",
                                paddingTop: "0px",
                                paddingRight: "10px",
                              }}
                              iconSize="sm"
                              throttleOnClick={false}
                            ></ButtonIcon>

                            <div>
                              {"S" +
                                moment(
                                  this.state.dateFiltreSemaineAffichees
                                ).isoWeek() +
                                " (" +
                                moment(
                                  this.state.dateFiltreSemaineAffichees
                                ).isoWeekYear() +
                                ")"}
                            </div>

                            <ButtonIcon
                              icon={faAngleRight}
                              className="btn"
                              onClick={() => {
                                this.filtrerSelonSemaine(null, true);
                                this.changeDateWeek(1);
                                this.setState({
                                  selectAllRESP: false,
                                  selectAllRH: false,
                                });
                              }}
                              style={{
                                paddingLeft: "10px",
                                paddingBottom: "6px",
                                paddingTop: "0px",
                              }}
                              iconSize="sm"
                              throttleOnClick={false}
                            ></ButtonIcon>
                          </div>
                        </div>
                        <div style={{ fontSize: "0.75rem" }}>
                          {moment(this.state.dateFiltreSemaineAffichees)
                            .isoWeekday(1)
                            .format("DD/MM/YY") +
                            " au " +
                            moment(this.state.dateFiltreSemaineAffichees)
                              .isoWeekday(7)
                              .format("DD/MM/YY")}
                        </div>
                      </div>
                      <div className="col-2 align-self-center py-2">
                        Filtrer selon ce mois :
                        <Input
                          value={this.state.filterByMonth}
                          type="checkbox"
                          handleChange={this.filtrerSelonMois}
                        />
                        <ButtonIcon
                          icon={faAngleLeft}
                          className="btn"
                          onClick={() => {
                            this.filtrerSelonMois(null, true);
                            this.changeDateMois(-1);
                            this.setState({
                              selectAllRESP: false,
                              selectAllRH: false,
                            });
                          }}
                          style={{
                            paddingLeft: "10px",
                            paddingBottom: "6px",
                            paddingTop: "0px",
                            paddingRight: "10px",
                          }}
                          iconSize="sm"
                          throttleOnClick={false}
                        ></ButtonIcon>
                        {moment(this.state.dateFiltreMoisAffichees).format(
                          "MM/YYYY"
                        )}
                        <ButtonIcon
                          icon={faAngleRight}
                          className="btn"
                          onClick={() => {
                            this.filtrerSelonMois(null, true);
                            this.changeDateMois(1);
                            this.setState({
                              selectAllRESP: false,
                              selectAllRH: false,
                            });
                          }}
                          style={{
                            paddingLeft: "10px",
                            paddingBottom: "6px",
                            paddingTop: "0px",
                          }}
                          iconSize="sm"
                          throttleOnClick={false}
                        ></ButtonIcon>
                      </div>
                      <div className="col-3 align-self-center py-2">
                        <div>
                          Nombre total d'heures pendant cette période :
                          <br />
                          <Input
                            type="decimal"
                            disabled
                            showClearButton={false}
                            value={this.state.heures
                              ?.slice()
                              .filter((h) => {
                                return !h.deleted;
                              })

                              .filter((h) => {
                                if (
                                  [
                                    "ARRET",
                                    "HRECU",
                                    "RPNUI",
                                    "RPSAM",
                                    "RPDIM",
                                    "RPFER",
                                    "REPAS",
                                    "DEPLA",
                                  ].includes(h.rubrique?.code)
                                )
                                  return false;
                                else return true;
                              })
                              .map((h) => h.nombre ?? 0)
                              .reduce((a, b) => a + b, 0)}
                          />
                        </div>
                      </div>

                      <div className="col-2 align-self-center py-2">
                        <div
                          style={{
                            width: "80px",
                            flexShrink: "0",
                          }}
                        >
                          Rubrique :
                        </div>
                        <Input
                          accessor="rubriqueId"
                          type="selectSearch"
                          service={HeurePersonnelRubriqueService.getAll}
                          valueFieldToDisplay={"designation"}
                          optionFieldToDisplay={"designation"}
                          optionFieldToReturn={"id"}
                          handleChange={this.handleChange}
                        />
                      </div>
                      <table className="mt-1 text-uppercase heuresTable table table-sm table-striped table-bordered">
                        <thead
                          className={
                            "sticky-top" +
                            (this.state.heures?.length != 0
                              ? ""
                              : " theadBorderRadius")
                          }
                        >
                          <tr>
                            <th>Rubrique</th>
                            <th className="maxWidth130">Competence</th>
                            <th>Affaire</th>
                            <th>Libellé</th>
                            <th
                              className="maxWidth130 hover"
                              onClick={() => {
                                if (this.state.sortBy != "date")
                                  this.setState({
                                    sortBy: "date",
                                    searchDesc: true,
                                  });
                                else
                                  this.setState({
                                    searchDesc: !this.state.searchDesc,
                                  });
                              }}
                            >
                              <div className="d-flex">
                                <div className="me-1">Date</div>
                                <FontAwesomeIcon
                                  icon={this.getSortIcon("date")}
                                  size="lg"
                                  className="orderby sortColumns"
                                />
                              </div>
                            </th>
                            <th className="maxWidth75">Nombre</th>
                            <th
                              style={
                                !isResponsable &&
                                !isRH &&
                                rightVisibiliteCocheHeure != "RW"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              RESP
                              <Input
                                accessor={"selectAllRESP"}
                                type="checkbox"
                                value={this.state.selectAllRESP}
                                placeholder=""
                                showValidator={false}
                                showClearButton={false}
                                handleChange={this.handleChange}
                                disabled={
                                  (!(rightModifCocheResponsable == "RW") &&
                                    !isRH) ||
                                  !this.props.editing
                                }
                              />
                            </th>
                            <th
                              style={
                                !isRH && rightVisibiliteCocheHeure != "RW"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              RH
                              <Input
                                accessor={"selectAllRH"}
                                value={this.state.selectAllRH}
                                type="checkbox"
                                placeholder=""
                                handleChange={this.handleChange}
                                showValidator={false}
                                showClearButton={false}
                                disabled={!isRH || !this.props.editing}
                              />
                            </th>
                            <th
                              style={
                                !isRH && rightVisibiliteCocheHeure != "RW"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              Auto
                            </th>
                            <th
                              style={
                                !isRH && rightCommentaireHeure != "RW"
                                  ? { display: "none" }
                                  : {}
                              }
                            >
                              Commentaire
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.loadingHeure ? (
                            <tr
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <td colSpan={13}>
                                <FontAwesomeIcon
                                  icon={faSpinner}
                                  size="lg"
                                  className="fa-spin"
                                />
                              </td>
                            </tr>
                          ) : (
                            this.state.heures
                              ?.slice()
                              .filter((h) => {
                                return !h.Fille;
                              })
                              .filter((h) => {
                                return !h.deleted;
                              })

                              .sort((a, b) => {
                                //premier critère d'ordre : la date/rubrique
                                let bDate = new Date(b.date);
                                let aDate = new Date(a.date);

                                if (
                                  bDate.getDate() != aDate.getDate() ||
                                  bDate.getMonth() != aDate.getMonth() ||
                                  bDate.getFullYear() != aDate.getFullYear()
                                ) {
                                  return (
                                    (bDate - aDate) *
                                    (this.state.searchDesc ? -1 : 1)
                                  );
                                }

                                //deuxième critère d'ordre : la rubrique par ordre Sol Solution
                                if (
                                  a.rubrique?.ordreTri != b.rubrique?.ordreTri
                                )
                                  return (
                                    a.rubrique?.ordreTri - b.rubrique?.ordreTri
                                  );

                                //troisième critère d'ordre : la rubrique par ordre lexicographique
                                const nameA = a.rubrique?.code?.toUpperCase(); // ignore upper and lowercase
                                const nameB = b.rubrique?.code?.toUpperCase(); // ignore upper and lowercase

                                if (a.rubrique == null || nameA < nameB) {
                                  return -1;
                                }
                                if (b.rubrique == null || nameA > nameB) {
                                  return 1;
                                }

                                // names must be equal
                                return 0;
                              })
                              .map((heure) => (
                                <LigneHeure
                                  key={heure.id}
                                  heure={heure}
                                  handleChange={this.handleChange}
                                  editing={this.props.editingHeuresPersonnel}
                                  deleteLineHeurePersonnel={
                                    this.deleteLineHeurePersonnel
                                  }
                                  copierLigneHeure={this.copierLigneHeure}
                                  collerLigneHeure={this.collerLigneHeure}
                                />
                              ))
                          )}
                        </tbody>
                      </table>
                    </>
                  }
                />
              </FormWithValidation>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

Heures.propTypes = {
  personnel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { Heures };
