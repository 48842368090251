import React from "react";
import { App } from "App";

import { Box, DialogForm, FiltrePersonnalise } from "_components";
import produce from "immer";

import { ButtonIcon, WarningBar } from "_components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";
import {
  ProcessTacheService,
  PersonnelService,
  ProduitInterneService,
  ClientService,
  ActionsTacheService,
  FamilleAffaireService,
} from "_services";
import { Input } from "_components/Input";

class HistoriqueClient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      processIds: null,
      personnelsIds: null,
      actionsIds: null,
      produitInternesIds: null,
      historiques: [],
      historique: {},
    };

    this.GetHistorique = this.GetHistorique.bind(this);
    this.renderDialog = this.renderDialog.bind(this);
    this.setOpenDialogCreationHisto =
      this.setOpenDialogCreationHisto.bind(this);

    this.setForceUpdateActionHistorique =
      this.setForceUpdateActionHistorique.bind(this);
  }

  componentDidMount() {
    this.GetHistorique();
  }

  GetHistorique() {
    ClientService.getpostHistorique({
      processIds: this.state.processIds,
      personnelsIds: this.state.personnelsIds,
      actionsIds: this.state.actionsIds,
      produitInternesIds: this.state.produitInternesIds,
      client: this.props.client.id,
    }).then((res) => this.setState({ historiques: res.data }));
    this.timeoutId = null;
  }

  setOpenDialogCreationHisto(openDialogFunc) {
    this.setState({ openDialogCreationHistoFunc: openDialogFunc });
  }
  setForceUpdateActionHistorique(callbackFunction) {
    this.setState({ callbackForceUpdateActionHistorique: callbackFunction });
  }
  renderDialog() {
    return (
      <div>
        <DialogForm
          tooltip="Ajouter un historique"
          classNameButton="btn btn-success"
          dialogTitle="Ajouter un historique"
          labelValidateButton="Valider"
          setOpenDialog={this.setOpenDialogCreationHisto}
          onValidate={() => {
            ClientService.postHistorique(
              this.props.client.id,
              this.state.historique
            ).then(() => {
              this.GetHistorique();
              this.setState({ historique: {} });
            });
          }}
          onClose={() => {}}
          body={
            <div
              id="PopupCommande"
              className={"col-lg-12 row"}
              style={{ minWidth: "1000px" }}
            >
              <div className={"col-lg-6"}>
                <Input
                  value={this.state.historique.process}
                  label="Process"
                  accessor="process"
                  type="selectSearch"
                  service={ProcessTacheService.getAll}
                  showClearButton={false}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        if (prevState.historique.process != value) {
                          prevState.historique.action = null;
                        }
                        prevState.historique.process = value;
                      }),
                      this.state.callbackForceUpdateActionHistorique
                    );
                  }}
                />

                <Input
                  value={this.state.historique.action}
                  label="Action"
                  accessor="action"
                  type="selectSearch"
                  service={() =>
                    ActionsTacheService.getAll({
                      processId: this.state.historique.process?.id,
                    })
                  }
                  showClearButton={false}
                  optionFieldToDisplay={["designation"]}
                  optionFieldToReturn={"designation"}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.historique.action = value;
                        if (value?.process != null) {
                          prevState.historique.process = value?.process;
                        }
                      })
                    );
                  }}
                  setForceUpdateFunction={this.setForceUpdateActionHistorique}
                />
              </div>
              <div className={"col-lg-6"}>
                <Input
                  value={this.state.historique.produitInterne}
                  type="selectSearch"
                  accessor="produitInterne"
                  label="Produit Interne"
                  service={ProduitInterneService.getAll}
                  showClearButton={false}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.historique.produitInterne = value;
                      })
                    );
                  }}
                />
                <Input
                  value={this.state.historique.familleAffaire}
                  accessor="familleAffaire"
                  type="selectSearch"
                  label="Famille d'affaire"
                  service={FamilleAffaireService.getAll}
                  showClearButton={false}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.historique.familleAffaire = value;
                      })
                    );
                  }}
                />
                <Input
                  value={this.state.historique.contact}
                  accessor="contact"
                  type="selectSearch"
                  label="Contact"
                  options={this.props.client.contacts}
                  showClearButton={false}
                  optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.historique.contact = value;
                      })
                    );
                  }}
                />
              </div>
              <div className={"col-lg-12"}>
                <Input
                  value={this.state.historique.description}
                  accessor="description"
                  type="textArea"
                  label="Description"
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.historique.description = value;
                      })
                    );
                  }}
                />
              </div>
            </div>
          }
        />
      </div>
    );
  }

  render() {
    let right = App.RightsGuard?.current?.hasRight("Client", "Historique");
    if (right == "RW" || right == "RO") {
      return (
        <>
          {this.renderDialog()}
          <Box
            header={
              <>
                <div className="col-12 align-self-center py-2 d-flex flex-row justify-content-between">
                  <div>Filtres</div>
                  <div className=" a d-flex flex-row ">
                    <FiltrePersonnalise
                      page={"ClientsHistorique"}
                      getFiltre={() => {
                        return {
                          processIds: this.state.processIds,
                          personnelsIds: this.state.personnelsIds,
                          actionsIds: this.state.actionsIds,
                          produitInternesIds: this.state.produitInternesIds,
                        };
                      }}
                      handleLoadFiltre={(value) => {
                        this.setState({ ...value }, this.GetHistorique);
                      }}
                    />
                  </div>
                </div>
              </>
            }
            body={
              <>
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <th className="solwayFont">Process</th>
                      <th className="solwayFont">Personnel</th>
                      <th className="solwayFont">Produit Interne</th>
                      <th className="solwayFont">Action</th>
                    </tr>
                    <tr>
                      <td>
                        <Input
                          value={this.state.processIds}
                          accessor="processIds"
                          type="multipleSelectSearch"
                          service={ProcessTacheService.getAll}
                          valueFieldToDisplay={"designation"}
                          optionFieldToDisplay={"designation"}
                          optionFieldToReturn={"id"}
                          handleChange={(acc, val) =>
                            this.setState(
                              { processIds: val },
                              this.GetHistorique
                            )
                          }
                        />
                      </td>
                      <td>
                        <Input
                          value={this.state.personnelsIds}
                          accessor="personnelsIds"
                          type="multipleSelectSearch"
                          service={PersonnelService.getAll}
                          valueFieldToDisplay={["nom", "prenom"]}
                          optionFieldToDisplay={["nom", "prenom"]}
                          optionFieldToReturn={"id"}
                          handleChange={(acc, val) =>
                            this.setState(
                              { personnelsIds: val },
                              this.GetHistorique
                            )
                          }
                        />
                      </td>
                      <td>
                        <Input
                          value={this.state.produitInternesIds}
                          accessor="produitInternesIds"
                          type="multipleSelectSearch"
                          service={ProduitInterneService.getAll}
                          valueFieldToDisplay={["designation"]}
                          optionFieldToDisplay={["designation"]}
                          optionFieldToReturn={"id"}
                          handleChange={(acc, val) =>
                            this.setState(
                              { produitInternesIds: val },
                              this.GetHistorique
                            )
                          }
                        />
                      </td>
                      <td>
                        <Input
                          value={this.state.actionsIds}
                          accessor="actionsIds"
                          type="multipleSelectSearch"
                          service={ActionsTacheService.getAll}
                          valueFieldToDisplay={["designation"]}
                          optionFieldToDisplay={["designation"]}
                          optionFieldToReturn={"id"}
                          handleChange={(acc, val) =>
                            this.setState(
                              { actionsIds: val },
                              this.GetHistorique
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            }
            rowClass="px-2"
          />

          <Box
            noPaddingBottom={true}
            header={
              <div className="row text-align-center">
                <div className="col-6 py-2">Historique Client</div>

                <div className="col-6 text-end py-2">
                  <ButtonIcon
                    smallText=""
                    icon={faPlus}
                    iconSize="sm"
                    onClick={() => {
                      this.state.openDialogCreationHistoFunc();
                    }}
                    className="btn btn-success"
                  ></ButtonIcon>
                </div>
              </div>
            }
            body={
              <div
                className="lock-header "
                style={{
                  padding: "0 0 0 0 ",
                }}
              >
                <table className="tableHistorique table table-striped table-bordered table-sm">
                  <thead
                    className={
                      "sticky-top" +
                      (this.state.historiques?.length == 0
                        ? " theadBorderRadius"
                        : "")
                    }
                  >
                    <tr>
                      <th className="text-nowrap ps-2">Date</th>
                      <th className="text-nowrap ps-2">Process</th>
                      <th className="text-nowrap ps-2">Action</th>
                      <th className="text-nowrap ps-2">Par</th>
                      <th className="text-nowrap ps-2">Document</th>
                      <th className="text-nowrap ps-2">Produit Interne</th>
                      <th className="text-nowrap ps-2">Famille d'affaire</th>
                      <th className="text-nowrap ps-2">Contact</th>
                      <th className="text-nowrap ps-2">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.historiques
                      ?.slice()
                      ?.sort(
                        (a, b) =>
                          new Date(b.dateCreation) - new Date(a.dateCreation)
                      )
                      .map((historique) => {
                        return (
                          <tr key={historique.id}>
                            <td className="ps-2">
                              {ToLocaleDateString(historique.dateCreation)}
                            </td>
                            <td className="ps-2">
                              {historique.process?.designation ?? "-"}
                            </td>
                            <td className="ps-2">{historique.action ?? "-"}</td>
                            <td className="ps-2">
                              {historique.finishedBy?.initiales ?? "-"}
                            </td>
                            <td
                              className="ps-2"
                              style={{ minWidth: "120px", maxWidth: "200px" }}
                            >
                              {historique.typeEntiteReference ? (
                                <a
                                  href={
                                    "/" +
                                    historique.typeEntiteReference +
                                    "/" +
                                    historique.idReference
                                  }
                                  target="_blank"
                                >
                                  {historique.typeEntiteReference}
                                  <wbr />
                                  {"/" + historique.idReference}
                                </a>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="ps-2">
                              {historique.produitInterne?.designation ?? "-"}
                            </td>
                            <td className="ps-2">
                              {historique.familleAffaire?.designation ?? "-"}
                            </td>
                            <td className="ps-2">
                              {historique.contact ? (
                                <a
                                  href={"/contacts/" + historique.contact.id}
                                  target="_blank"
                                >
                                  {historique.contact.prenom_Contact +
                                    " " +
                                    historique.contact.nom_Contact}
                                </a>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="ps-2 keepWhiteSpace">
                              {historique.description}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            }
          />
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

export { HistoriqueClient };
