import React, { Fragment, useEffect, useState } from "react";

import { ButtonIcon } from "_components";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";

import { PropTypes } from "prop-types";

const AccordionParAnnee = (props) => {

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect( () => setIsAccordionOpen(props.isInitiallyOpen), []);
  const renderAccordionFirstLine =() => {
    return (
      <Fragment key={props.annee}>
        <tr>
          <td>
            <ButtonIcon
              icon={isAccordionOpen ? faMinusSquare : faPlusSquare}
              className="btn"
              onClick={() =>
                setIsAccordionOpen(!isAccordionOpen)
                
              }
              style={{ width: "32px" }}
            />
            {props.annee}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </Fragment>
    );
  };

  
    if (!isAccordionOpen) {
      return renderAccordionFirstLine();
    } else {
      return (
        <>
          {renderAccordionFirstLine()}
          

          {props.factures.map((facture, index) => {
            return (
              <tr key={index}>
                <td></td>
                <td>Facture : {facture.num_Facture}</td>
                <td>{ToLocaleDateString(facture.dateCreation)}</td>
                <td>{facture.prixTotalHT}</td>
              </tr>
            );
          })}
        </>
      );
    }
  
};

AccordionParAnnee.propTypes = {
  index: PropTypes.number,
  annee: PropTypes.string,
  isInitiallyOpen: PropTypes.bool,
  devis: PropTypes.any,
  commandes: PropTypes.any,
  factures: PropTypes.any,
};

export { AccordionParAnnee };
