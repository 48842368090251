import React from "react";
//import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash, faPrint } from "@fortawesome/free-solid-svg-icons";
import {
  AffaireService,
  CommandeService,
  SageDocumentService,
  ProcessTacheService,
  ActionsTacheService,
} from "_services";
import * as FileSaver from "file-saver";
import { App } from "App";

import {
  InformationsAffaire,
  ChantierAffaire,
  FinancierAffaire,
  Devis,
  InfoCommandes,
  Factures,
  NoteDeFrais,
  Heures,
  CommandesAchat,
  HistoriqueAffaire,
  Calculette,
} from ".";
import {
  ButtonIconWithValidation,
  WarningBar,
  ButtonIcon,
  DialogForm,
} from "_components";
import { Input } from "_components/Input";

import { Helmet } from "react-helmet";

import produce from "immer";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheAffaire extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingDevisCommandes: true,
      loadingFactures: true,
      tabs: [
        "Général",
        "Financier",
        "Chantier",
        "Devis",
        "Commandes",
        "Factures",
        "Commandes Achat",
        "Historique",
        "Calculette",
      ],
      active: "Général",
      editing: false,
      affaire: {},
      historique: {},
      openDialogImpressionFunc: null,
      bonsAchats: null,
      loadingBonsAchats: true,
    };

    this.renderActive = this.renderActive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.updateCommandes = this.updateCommandes.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.setOpenDialogImpression = this.setOpenDialogImpression.bind(this);
    this.setOpenDialogCreationHisto =
      this.setOpenDialogCreationHisto.bind(this);
    this.setForceUpdateActionHistorique =
      this.setForceUpdateActionHistorique.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("AffaireOnglet") == null) {
      sessionStorage.setItem("AffaireOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("AffaireOnglet") });
    }

    let { id } = this.props.match.params;
    AffaireService.getById(id).then((res) => {
      this.setState(
        produce((prevState) => {
          prevState.affaire = res.data;
          prevState.loading = false;
          prevState.tabs = [
            "Général",
            "Financier",
            "Chantier",
            //...(res.data.produitInterne?.isPresta ? ["Coût rentabilité"] : []),
            "Devis",
            "Commandes",
            "Factures",
            "Notes de Frais",
            "Heures",
            "Commandes Achat",
            "Historique",
            "Calculette",
          ];
        })
      );

      AffaireService.getNdF(id).then((res) => {
        this.setState({
          NdF: res.data,
        });
      });

      AffaireService.getFactures(id).then((res) => {
        this.setState({
          factures: res.data,
          loadingFactures: false,
        });
      });

      SageDocumentService.getBonsAchats(res.data?.reference).then((res) => {
        this.setState({
          bonsAchats: res.data,
          loadingBonsAchats: false,
        });
      });

      AffaireService.getCommandes(id).then((res) => {
        this.setState({
          commandes: res.data,
          loadingDevisCommandes: false,
        });
      });
    });
  }

  setOpenDialogImpression(openDialogFunc) {
    this.setState({ openDialogImpressionFunc: openDialogFunc });
  }

  setOpenDialogCreationHisto(openDialogFunc) {
    this.setState({ openDialogCreationHistoFunc: openDialogFunc });
  }

  setForceUpdateActionHistorique(callbackFunction) {
    this.setState({ callbackForceUpdateActionHistorique: callbackFunction });
  }

  updateHisto(newHistorique) {
    this.setState(
      produce((prevState) => {
        prevState.affaire.historiques = prevState.affaire.historiques.concat([
          newHistorique,
        ]);
      })
    );
  }

  handleChange(accessor, value, callBackFunction) {
    if (accessor.includes("affaire")) {
      //Undo
      this.setState({ affaire: value }, callBackFunction);
    } else {
      //Update

      if (accessor.includes(".")) {
        var accessorSplit = accessor.split(".");
        // Nested property (separated by a '.')
        this.setState(
          (prevState) => ({
            affaire: {
              ...prevState.affaire,
              [accessorSplit[0]]: {
                ...prevState.affaire[accessorSplit[0]],
                [accessorSplit[1]]: value,
              },
            },
          }),
          callBackFunction
        );
      } else {
        // Shallow property

        this.setState(
          (prevState) => ({
            affaire: {
              ...prevState.affaire,
              [accessor]: value,
            },
          }),
          callBackFunction
        );
      }
    }
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  handleUpdate() {
    return AffaireService.put(this.state.affaire).then((response) =>
      this.setState({ affaire: response.data })
    );
  }

  handleDelete() {
    console.log("TODO DELETE");
  }

  updateCommandes(commande, typeRequest) {
    let index = 0;
    let commandes = [...this.state.affaire.commandes];
    for (let i = 0; i < this.state.affaire.commandes.length; i++) {
      if (commandes[i]?.id === commande?.id) {
        index = i;
        break;
      }
    }
    switch (typeRequest) {
      case "post":
        commandes.splice(index, 1, commande);
        this.setState(
          {
            affaire: {
              ...this.state.affaire,
              commandes: [...commandes],
            },
          },
          () => this.handleUpdate()
        );

        break;
      case "delete":
        var commandeToUnlink = commandes[index];
        commandes.splice(index, 1);

        this.setState(
          {
            affaire: {
              ...this.state.affaire,
              commandes: [...commandes],
            },
          },
          () => CommandeService.unlink(commandeToUnlink)
        );

        break;
      default:
        console.log("le type de requete n'est pas encore géré.");
    }
  }

  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("AffaireOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
        <div>
          <DialogForm
            tooltip="Ajouter un historique"
            classNameButton="btn btn-success"
            dialogTitle="Ajouter un historique"
            labelValidateButton="Valider"
            setOpenDialog={this.setOpenDialogCreationHisto}
            onValidate={() => {
              AffaireService.postHistorique({
                ...this.state.historique,
                affaire: this.state.affaire,
              }).then((res) => {
                this.updateHisto(res.data);
              });
            }}
            onClose={() => {}}
            body={
              <div
                id="PopupCommande"
                className={"col-lg-12 row"}
                style={{ minWidth: "1000px" }}
              >
                <div className={"col-lg-6"}>
                  <Input
                    value={this.state.historique.process}
                    label="Process"
                    accessor="process"
                    type="selectSearch"
                    service={ProcessTacheService.getAll}
                    showClearButton={false}
                    optionFieldToDisplay={["designation"]}
                    valueFieldToDisplay={["designation"]}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          if (prevState.historique.process != value) {
                            prevState.historique.action = null;
                          }
                          prevState.historique.process = value;
                        }),
                        this.state.callbackForceUpdateActionHistorique
                      );
                    }}
                  />
                </div>
                <div className={"col-lg-6"}>
                  <Input
                    value={this.state.historique.action}
                    label="Action"
                    accessor="action"
                    type="selectSearch"
                    service={() =>
                      ActionsTacheService.getAll({
                        processId: this.state.historique.process?.id,
                      })
                    }
                    showClearButton={false}
                    optionFieldToDisplay={["designation"]}
                    valueFieldToDisplay={["designation"]}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.historique.action = value;
                          if (value?.process != null) {
                            prevState.historique.process = value?.process;
                          }
                        })
                      );
                    }}
                    setForceUpdateFunction={this.setForceUpdateActionHistorique}
                  />
                </div>
                <div className={"col-lg-12"}>
                  <Input
                    value={this.state.historique.description}
                    accessor="description"
                    type="textArea"
                    label="Description"
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.historique.description = value;
                        })
                      );
                    }}
                  />
                </div>
              </div>
            }
          />
          <DialogForm
            tooltip="Imprimer contrat"
            classNameButton="btn btn-success"
            dialogTitle="Impression"
            labelValidateButton="Valider"
            setOpenDialog={this.setOpenDialogImpression}
            validation={() => {
              if (this.state.impression != null) return true;
              else {
                App.Toaster.current?.createToast({
                  body: "Vous devez sélectionner un modèle",
                  header: "Echec",
                  type: "failure",
                });

                return false;
              }
            }}
            onValidate={() => {
              AffaireService.print(
                this.state.impression.designation,
                this.state.affaire.id
              ).then((res) => {
                var blob = new Blob([res?.data], {
                  type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                FileSaver.saveAs(
                  blob,
                  this.state.impression.designation +
                    "_" +
                    this.state.affaire.reference +
                    ".docx"
                );
              });
            }}
            onClose={() => {}}
            body={
              <div id="PopupCommande">
                <div>
                  Veuillez choisir un modèle d'impression <br />
                </div>

                <Input
                  label="Modèle"
                  accessor="impression"
                  type="selectSearch"
                  value={this.state.impression}
                  valueFieldToDisplay="designation"
                  optionFieldToDisplay="designation"
                  options={[
                    {
                      id: 0,
                      designation: "Lettre envoi rapport 3CO MOE",
                    },
                    {
                      id: 1,
                      designation: "Lettre envoi rapport 3CO COFRAC",
                    },
                    {
                      id: 2,
                      designation: "Pochette ENDO",
                    },
                    {
                      id: 3,
                      designation: "Pochette ETU",
                    },
                  ]}
                  functionAppliedToValue={(value) => {
                    if (value) return value.designation;
                    return null;
                  }}
                  handleChange={(acc, value) =>
                    this.setState({ impression: value })
                  }
                  required={true}
                />
              </div>
            }
          />
        </div>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <InformationsAffaire
            affaire={this.state.affaire}
            stateFieldNameToUpdate="affaire"
            handleChange={this.handleChange}
            service={AffaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          ></InformationsAffaire>
        );
      case "Financier":
        return (
          <FinancierAffaire
            affaire={this.state.affaire}
            factures={this.state.factures}
            commandes={this.state.commandes}
            stateFieldNameToUpdate="affaire"
            handleChange={this.handleChange}
            service={AffaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            loading={
              this.state.loadingDevisCommandes || this.state.loadingFactures
            }
          ></FinancierAffaire>
        );
      case "Chantier":
        return (
          <ChantierAffaire
            affaire={this.state.affaire}
            stateFieldNameToUpdate="affaire"
            handleChange={this.handleChange}
            service={AffaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          ></ChantierAffaire>
        );
      case "Devis":
        return (
          <Devis
            affaire={this.state.affaire}
            commandes={this.state.commandes}
            loadingDevisCommandes={this.state.loadingDevisCommandes}
          ></Devis>
        );
      case "Commandes":
        return (
          <InfoCommandes
            affaire={this.state.affaire}
            commandes={this.state.commandes}
            stateFieldNameToUpdate="affaire"
            handleCommandes={this.updateCommandes}
            handleChange={this.handleChange}
            service={AffaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            loadingDevisCommandes={this.state.loadingDevisCommandes}
          ></InfoCommandes>
        );
      case "Factures":
        return (
          <Factures
            affaire={this.state.affaire}
            factures={this.state.factures}
            stateFieldNameToUpdate="affaire"
            handleChange={this.handleChange}
            service={AffaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          />
        );
      case "Notes de Frais":
        return (
          <NoteDeFrais
            NdF={this.state.NdF}
            stateFieldNameToUpdate="affaire"
            handleChange={this.handleChange}
            service={AffaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          />
        );
      case "Heures":
        return (
          <Heures
            affaire={this.state.affaire}
            stateFieldNameToUpdate="affaire"
            handleChange={this.handleChange}
            service={AffaireService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          />
        );
      case "Commandes Achat":
        return (
          <CommandesAchat
            loading={this.state.loadingBonsAchats}
            bonsAchats={this.state.bonsAchats}
          />
        );
      case "Historique":
        return (
          <HistoriqueAffaire
            affaire={this.state.affaire}
            openDialogCreationHistoFunc={this.state.openDialogCreationHistoFunc}
          />
        );
      case "Calculette":
        return (
          <Calculette
            affaire={this.state.affaire}
            openDialogCreationHistoFunc={this.state.openDialogCreationHistoFunc}
            stateFieldNameToUpdate="affaire"
            editing={this.state.editing}
            handleChange={this.handleChange}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          />
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isAffaireEmpty = !Object.entries(this.state.affaire).length;
    return (
      <>
        <Helmet>
          <title>
            {"Affaire : "
              .concat(this.state.affaire.reference)
              .concat(" - ")
              .concat(this.state.affaire.nomAffaire)}
          </title>
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheAffaire">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Affaire : "
                  .concat(this.state.affaire.reference)
                  .concat(" - ")
                  .concat(this.state.affaire.nomAffaire)}
              </h4>
              <div className="d-flex">
                <Link to="/affaires" className="btn btn-primary nowrap ms-1">
                  Base Affaire
                </Link>
                <ButtonIcon
                  icon={faPrint}
                  className="btn btn-success text-light ms-1 "
                  tooltip="Impression"
                  onClick={() => {
                    this.state.openDialogImpressionFunc();
                  }}
                />
                {App.RightsGuard.current?.hasRight("Affaire", "Suppression") ==
                "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="form-control btn btn-danger text-light ms-1"
                    alertTitle=" Suppression"
                    alertBody={
                      <div>
                        Souhaitez-vous réellement supprimer cette affaire ?{" "}
                        <br />
                      </div>
                    }
                    alertButtonValidationText="Oui, je veux supprimer."
                  />
                ) : null}
                {/* {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )} */}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={!this.state.affaire?.soldee} // inversion car soldée=true != actif=true
              content={"Cette affaire est soldée"}
            />
            <WarningBar
              active={this.state.affaire?.existeDansSage}
              content={"Cette affaire n'existe pas dans Sage."}
            />
            {!isAffaireEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

export { FicheAffaire };
