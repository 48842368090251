import React from "react";
import { App } from "App";
import { PropTypes } from "prop-types";

import {
  IfNullToText,
  RedirectToDetailsNewTab,
  RedirectToDetails,
} from "_utils";

import {
  ButtonIcon,
  DataFieldRow,
  ButtonIconWithValidation,
  AlertDialog,
  Box,
  FormWithValidation,
} from "_components";

import { Input } from "_components/Input";

import {
  faCheck,
  //faClone,
  faPen,
  faTimes,
  faTrash,
  faExternalLinkAlt,
  faPaste,
} from "@fortawesome/free-solid-svg-icons";

import { ContactService, FonctionService } from "_services";
import produce from "immer";

import { getIconLicence } from "_utils";

/**
 * Génère un composant boite spécifique au contact des clients
 * Cette boite permet de gérer un contact (redirection sur la fiche du contact ,modification, dupliquation, suppression)
 */
class BoxContact extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      erreur: false,
      edition: false,
      saveContact: {},
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickEdition = this.handleClickEdition.bind(this);
    this.handleCliCkDuplicate = this.handleCliCkDuplicate.bind(this);
    this.handleUndoContact = this.handleUndoContact.bind(this);
    this.handleUpdateContact = this.handleUpdateContact.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickNewTabContact = this.handleClickNewTabContact.bind(this);
    this.handleClickContact = this.handleClickContact.bind(this);
    this.collerAdresse = this.collerAdresse.bind(this);
  }

  componentDidMount() {
    this.setState({
      contact: this.props.contact,
    });
  }

  handleInputChange(accessor, value) {
    if (accessor.includes(".")) {
      // Nested property (separated by a '.')
      var accessorSplit = accessor.split(".");

      this.setState((prevState) => ({
        contact: {
          ...prevState.contact,
          [accessorSplit[0]]: {
            ...prevState.contact[accessorSplit[0]],
            [accessorSplit[1]]: value,
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        contact: {
          ...prevState.contact,
          [accessor]: value,
        },
      }));
    }
  }

  handleClose() {
    this.setState({ erreur: false });
  }

  handleDelete() {
    return ContactService.delete(this.state.contact, this.props.clientId).then(
      () => {
        this.props.updateFunction(this.state.contact, "delete");
      }
    );
  }

  handleCliCkDuplicate() {
    this.setState(
      (prevState) => ({
        contact: {
          ...prevState.contact,
          N_client: this.props.clientId,
        },
      }),
      () =>
        ContactService.post(this.state.contact)
          .then((res) => {
            this.props.updateFunction(this.state.contact, "post", res);
          })
          .catch((error) => {
            console.error(error);
            this.setState({ erreur: true });
          })
    );
  }

  handleClickEdition() {
    this.setState({ saveContact: this.state.contact });
    this.setState({ edition: !this.state.edition });
  }

  handleUndoContact() {
    this.setState({ contact: this.state.saveContact });
    this.setState({ edition: !this.state.edition });
  }

  handleUpdateContact(event) {
    ContactService.put(this.state.contact)
      .then(() => {
        this.props.updateFunction(this.state.contact, "put");

        App.Toaster?.current?.createToast({
          body: this.props.bodyToast ?? "Mis à jour",
          header: (this.props.headerToast ?? " ") + " Succès",
          type: "success",
        });
      })
      .catch((error) => {
        console.error(error);

        App.Toaster?.current?.createToast({
          body: this.props.bodyToast ?? "Mis à jour",
          header: (this.props.headerToast ?? "") + " Echec",
          type: "failure",
        });

        this.setState({ contact: this.state.saveContact });
        this.setState({ erreur: true, errorText: error.response?.data?.cause });
      });
    this.setState({ edition: !this.state.edition });
    event.preventDefault();
  }

  handleClickNewTabContact() {
    RedirectToDetailsNewTab("/contacts/", this.state.contact.id);
  }

  handleClickContact() {
    RedirectToDetails(this.props.history, "/contacts/", this.state.contact.id);
  }

  collerAdresse() {
    if (this.props.adresseAColler != null)
      this.setState(
        produce((prevState) => {
          var adresseARemplace = prevState.contact.adresse;
          var adresseACopie = this.props.adresseAColler;

          adresseARemplace.intitule = adresseACopie.intitule;
          adresseARemplace.complement = adresseACopie.complement;
          adresseARemplace.voie = adresseACopie.voie;
          adresseARemplace.ville = adresseACopie.ville;
          adresseARemplace.pays = adresseACopie.pays;
          adresseARemplace.region = adresseACopie.region;
          adresseARemplace.cp = adresseACopie.cp;
          adresseARemplace.lon = adresseACopie.lon;
          adresseARemplace.lat = adresseACopie.lat;
          adresseARemplace.precision = adresseACopie.precision;
        })
      );
  }

  render() {
    return (
      <>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            "La mise à jour des données est impossible. " + this.state.errorText
          }
          valider="Ok"
          open={this.state.erreur}
          handleClose={this.handleClose}
        />
        <FormWithValidation onSubmit={this.handleUpdateContact}>
          <Box
            header={
              <>
                <div className="col py-1 align-self-center fontSizeNormale">
                  {this.state.edition ? (
                    <div className="row">
                      <div className="col-6">
                        <div>Nom : </div>

                        <Input
                          value={this.props.contact.nom_Contact}
                          accessor="nom_Contact"
                          type="text"
                          colSize={12}
                          handleChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <div>Prénom : </div>
                        <Input
                          value={this.props.contact.prenom_Contact}
                          accessor="prenom_Contact"
                          type="text"
                          colSize={12}
                          handleChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="hover"
                      onClick={this.handleClickContact}
                      style={{ textTransform: "none" }}
                    >
                      <div className="fw-light">NOM, Prénom : </div>
                      <div className="poppinsFont">
                        {IfNullToText(
                          this.state.contact.nom_Contact?.toUpperCase(),
                          ""
                        ) +
                          ", " +
                          IfNullToText(this.state.contact.prenom_Contact, "")}
                      </div>
                    </div>
                  )}
                </div>
                {this.props.licence != null ? (
                  <a
                    href={"#licence" + this.props.licence?.numero}
                    className="col-auto py-1 align-self-center"
                    onClick={() => {
                      this.props.redirectToLicence();
                    }}
                  >
                    {this.props.licence.classification.categorie.id == 2
                      ? getIconLicence("Geosprint")
                      : getIconLicence("Websprint")}
                  </a>
                ) : null}
                <div className="col py-1 align-self-center text-end">
                  {this.props.NoEdition ? null : (
                    <div role="group" className="me-1 mt-1">
                      <ButtonIcon
                        icon={faPaste}
                        className="btn btn-success btn-sm text-light ms-1"
                        style={!this.state.edition ? { display: "none" } : null}
                        onClick={this.collerAdresse}
                        tooltip={"Coller l'adresse"}
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faCheck}
                        className="btn btn-success btn-sm text-light ms-1"
                        style={!this.state.edition ? { display: "none" } : null}
                        type="submit"
                      />
                      <ButtonIcon
                        icon={faTimes}
                        className="btn btn-danger btn-sm text-light ms-1"
                        style={!this.state.edition ? { display: "none" } : null}
                        onClick={this.handleUndoContact}
                      />
                      <ButtonIcon
                        icon={faExternalLinkAlt}
                        className="btn btn-secondary btn-sm text-light ms-1"
                        style={this.state.edition ? { display: "none" } : null}
                        onClick={this.handleClickNewTabContact}
                      />
                      <ButtonIcon
                        icon={faPen}
                        className="btn btn-secondary btn-sm text-light ms-1"
                        style={this.state.edition ? { display: "none" } : null}
                        onClick={this.handleClickEdition}
                      />
                      <ButtonIconWithValidation
                        icon={faTrash}
                        className="btn btn-danger btn-sm text-light ms-1"
                        style={this.state.edition ? { display: "none" } : null}
                        onClick={this.handleDelete}
                        alertTitle=" Suppression d'un contact du client"
                        alertBody={
                          (this.props.licence != null
                            ? "Ce contact dispose encore d'une licence logicielle. Si vous le supprimez, cette licence ne sera plus affectée à un utilisateur. "
                            : "") +
                          "Souhaitez-vous réellement supprimer ce contact ?"
                        }
                        alertButtonValidationText="Oui, je veux supprimer."
                      />
                    </div>
                  )}
                </div>
              </>
            }
            body={
              this.state.edition ? (
                <>
                  <DataFieldRow
                    label={"Civilité"}
                    value={IfNullToText(
                      this.props.contact.civilite?.designation,
                      "-"
                    )}
                    colSize={12}
                  />
                  <Input
                    label="Email"
                    value={this.props.contact.email}
                    accessor="email"
                    type="email"
                    colSize={12}
                    handleChange={this.handleInputChange}
                    required={this.props.licence != null}
                  />
                  <Input
                    label="Portable"
                    value={this.props.contact.portable}
                    accessor="portable"
                    type="text"
                    colSize={12}
                    handleChange={this.handleInputChange}
                  />
                  <Input
                    label="Ligne directe"
                    value={this.props.contact.ligne_Directe}
                    accessor="ligne_Directe"
                    type="text"
                    colSize={12}
                    handleChange={this.handleInputChange}
                  />
                  <Input
                    label="Standard"
                    value={this.props.contact.standard}
                    accessor="standard"
                    type="text"
                    colSize={12}
                    handleChange={this.handleInputChange}
                  />
                  <Input
                    label="Fonction"
                    value={this.props.contact.fonction}
                    accessor="fonction"
                    type="selectSearch"
                    service={FonctionService.getAll}
                    optionFieldToDisplay="designation"
                    valueFieldToDisplay="designation"
                    colSize={12}
                    handleChange={this.handleInputChange}
                  />
                  {/* <Input
                    label="Adresse de livraison"
                    values={[
                      this.props.contact.intitule,
                      this.props.contact.adresse,
                      this.props.contact.complement,
                      this.props.contact.ville,
                      this.props.contact.cp,
                    ]}
                    accessors={[
                      "intitule",
                      "adresse",
                      "complement",
                      "ville",
                      "cp",
                    ]}
                    colSize={12}
                    type="address"
                  /> */}
                  <Input
                    label="Adresse de livraison"
                    value={this.state.contact.adresse}
                    functionAppliedToValue={() => {
                      return (
                        <>
                          {this.props.contact.adresse?.intitule ?? ""}
                          <br />
                          {this.props.contact.adresse?.voie ?? ""}
                          <br />
                          {this.props.contact.adresse?.complement ?? ""}
                          <br />
                          {(this.props.contact.adresse?.cp ?? "") +
                            " " +
                            (this.props.contact.adresse?.ville ?? "")}
                          <br />
                          {this.props.contact.adresse?.pays?.nom_Fr ?? ""}
                        </>
                      );
                    }}
                    accessor="adresse"
                    colSize={12}
                    type="address"
                    handleChange={this.handleInputChange}
                    optionFieldToDisplay="nom_Fr"
                  />
                  <Input
                    label="Commentaire"
                    value={this.props.contact.commentaire}
                    accessor="commentaire"
                    type="text"
                    colSize={12}
                    handleChange={this.handleInputChange}
                  />
                </>
              ) : (
                <>
                  <DataFieldRow
                    label={"Civilité"}
                    value={IfNullToText(
                      this.props.contact.civilite?.designation,
                      "-"
                    )}
                    colSize={12}
                  />
                  <DataFieldRow
                    label={"Email"}
                    //value={makeEmailClickable(this.props.contact.email)}
                    value={IfNullToText(this.props.contact.email, "-")}
                    colSize={12}
                  />
                  <DataFieldRow
                    label={"Portable"}
                    value={IfNullToText(this.props.contact.portable, "-")}
                    colSize={12}
                  />
                  <DataFieldRow
                    label={"Ligne directe"}
                    value={IfNullToText(this.props.contact.ligne_Directe, "-")}
                    colSize={12}
                  />
                  <DataFieldRow
                    label={"Standard"}
                    value={IfNullToText(this.props.contact.standard, "-")}
                    colSize={12}
                  />
                  <DataFieldRow
                    label={"Fonction"}
                    value={IfNullToText(
                      this.props.contact.fonction?.designation,
                      "-"
                    )}
                    colSize={12}
                  />
                  <DataFieldRow
                    label="Adresse"
                    value={
                      <>
                        {this.props.contact.adresse?.intitule ?? ""}
                        <br />
                        {this.props.contact.adresse?.voie ?? ""}
                        <br />
                        {this.props.contact.adresse?.complement ?? ""}
                        <br />
                        {(this.props.contact.adresse?.cp ?? "") +
                          " " +
                          (this.props.contact.adresse?.ville ?? "")}
                        <br />
                        {this.props.contact.adresse?.pays?.nom_Fr ?? ""}
                      </>
                    }
                    colSize={12}
                  />
                  <DataFieldRow
                    label={"Commentaire"}
                    value={IfNullToText(this.props.contact.commentaire, "-")}
                    colSize={12}
                  />
                </>
              )
            }
          />
        </FormWithValidation>
      </>
    );
  }
}

BoxContact.propTypes = {
  contact: PropTypes.object,
  clientId: PropTypes.number,
  updateFunction: PropTypes.func,
  history: PropTypes.object,
  NoEdition: PropTypes.bool,
};

export { BoxContact };
