import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./FicheCommande.css";
import { App } from "App";

import { InformationsCommande, Details, Logistique } from ".";

import {
  AffaireService,
  CommandeService,
  ContratService,
  PersonnelService,
} from "_services";
import {
  faFileInvoiceDollar,
  faTrash,
  /*faUserFriends,*/
  faBalanceScale,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import produce from "immer";

import {
  computeCommandeDetail,
  computePrixVenteTotal,
  computeMontantTVA,
  computePrixVenteTotalTTC,
  computeTotals,
  computeSubTotals,
} from "./Details/DetailsFunctions.js";
import {
  ButtonIconWithValidation,
  ButtonIcon,
  DialogForm,
  AlertDialog,
  WarningBar,
} from "_components";
import { Input } from "_components/Input";
import * as FileSaver from "file-saver";

import { arrayMoveMutable } from "array-move";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheCommande extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: ["Général", "Détails", "Logistique"],
      active: "Général",
      editing: false,
      commande: {},
      affaire: {},
      open: false,
      numArcPasDejaVu: true,
      datePrevisionnelleRemiseRapportAff: new Date().toLocaleDateString(
        "fr-Fr"
      ),

      updating: false,
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.changeLinePosition = this.changeLinePosition.bind(this);
    this.addLineCommandeDetail = this.addLineCommandeDetail.bind(this);
    this.deleteLineCommandeDetail = this.deleteLineCommandeDetail.bind(this);
    this.newLine = this.newLine.bind(this);
    this.setOpenDialogDelaiPrev = this.setOpenDialogDelaiPrev.bind(this);
    this.getChampsObligatoireVide = this.getChampsObligatoireVide.bind(this);
  }

  /**
   * Permet de set la fonction permettant d'ouvrir la fenetre de dialogue pour remplir des infos pour l'affaire à créer
   */
  setOpenDialogDelaiPrev(openDialogFunc) {
    this.setState({ setOpenDialogDelaiPrevFunc: openDialogFunc });
  }

  componentDidMount() {
    if (sessionStorage.getItem("CommandeOnglet") == null) {
      sessionStorage.setItem("CommandeOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("CommandeOnglet") });
    }

    let { id } = this.props.match.params;
    CommandeService.getById(id).then((res) => {
      let commande = res.data;
      commande.commandeDetails.sort((a, b) => a.position - b.position);
      this.setState({
        commande: commande,
        loading: false,
        affaire: {
          commandes: [commande],
          delaiRealisation: null,
          datePrevisionnelleRemiseRapport: null,
        },
      });
    });
  }

  /**
   * Permet de renvoyer un tableau contenant le nom des champs vides obligatoires
   */
  getChampsObligatoireVide() {
    let tab = [];
    if (!this.state.commande?.client) {
      tab.push("Client");
    }
    if (!this.state.commande?.date_Livraison_Prevue) {
      tab.push("Date livraison prévue");
    }
    if (!this.state.commande?.produit_Interne) {
      tab.push("Produit Interne");
    }
    if (
      !this.state.commande?.famille_Affaire &&
      this.state.commande.produit_Interne?.isPresta
    ) {
      tab.push("Famille d'affaires");
    }
    if (!this.state.commande?.charge_Affaire) {
      tab.push("Chargé d'affaire");
    }

    return tab;
  }
  /**
   * Permet de mettre a jour l'etat de la commande sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value, callBackFunction) {
    if (accessor === "commande") {
      //Undo
      this.setState({ commande: value }, callBackFunction);
    } else {
      //Update
      if (accessor.includes(".")) {
        // Nested property
        var accessorSplit = accessor.split(".");

        if (accessorSplit[0] === "commandeDetail") {
          // Format devisDetail.id.accessor
          var commandeDetailId = accessorSplit[1];
          var commandeDetailAccessor = accessorSplit[2];
          let tauxTVA = this.state?.commande?.client?.tva?.valeur;
          let codeTarif = this.state?.commande?.client?.code_Tarif?.designation;

          if (commandeDetailAccessor === "article") {
            if (value) {
              // Si on chance l'article, on remplace les prix/designation par celui du nouvel article, et on réinitialise le reste.
              this.setState(
                produce((prevState) => {
                  let prevdd = prevState.commande.commandeDetails.find(
                    (dd) => dd.id == commandeDetailId
                  );
                  prevdd.article = value;
                  prevdd.description = value.description;
                  //prevdd.prixAchatUnitaire = value.prixAchat; // PrixAchat Obsolete
                  prevdd.coefficient = 1;
                  prevdd.quantite = 0;
                  prevdd.remise = 0;
                  if (codeTarif == "Tarif Export") {
                    prevdd.prixVenteUnitaire = value.prixVenteExport;
                  } else {
                    prevdd.prixVenteUnitaire = value.prixVenteFrance;
                  }
                  computePrixVenteTotal(prevdd);
                  computeMontantTVA(prevdd, tauxTVA);
                  computePrixVenteTotalTTC(prevdd);
                  computeTotals(prevState.commande);
                  computeSubTotals(prevState.commande.commandeDetails);
                }),
                callBackFunction
              );
            } else {
              // Si l'accesseur est article, mais qu'il n'y a pas de valeur donnée, on veut réinitialiser la ligne.
              this.setState(
                produce((prevState) => {
                  let prevdd = prevState.commande.commandeDetails.find(
                    (dd) => dd.id == commandeDetailId
                  );
                  prevdd.article = null;
                  prevdd.description = "";
                  prevdd.prixAchatUnitaire = 0;
                  prevdd.coefficient = 1;
                  prevdd.prixVenteUnitaire = 0;
                  prevdd.quantite = 0;
                  prevdd.remise = 0;
                  prevdd.prixVenteTotal = 0;
                  prevdd.montantTVA = 0;
                  prevdd.prixVenteTotalTTC = 0;
                  computeTotals(prevState.commande);
                  computeSubTotals(prevState.commande.commandeDetails);
                }),
                callBackFunction
              );
            }
          } else if (commandeDetailAccessor === "quantite") {
            // Si l'accesseur est quantite, on ne peut pas avoir de quantité à 0 si la ligne est une ligne article.
            this.setState(
              produce((prevState) => {
                let prevdd = prevState.commande.commandeDetails.find(
                  (dd) => dd.id == commandeDetailId
                );

                if (prevdd.type == 0) {
                  prevdd.quantite = value == 0 ? 1 : value ?? 1;
                } else {
                  prevdd.quantite = value;
                }

                computeCommandeDetail(
                  commandeDetailAccessor,
                  prevdd,
                  tauxTVA,
                  prevState.commande.coursDevise
                );
                computeTotals(prevState.commande);
                computeSubTotals(prevState.commande.commandeDetails);
              }),
              callBackFunction
            );
          } else {
            // Si l'accesseur est tout autre, on applique la valeur donnée dans le champ donné.
            this.setState(
              produce((prevState) => {
                let prevdd = prevState.commande.commandeDetails.find(
                  (dd) => dd.id == commandeDetailId
                );
                prevdd[commandeDetailAccessor] = value;
                computeCommandeDetail(
                  commandeDetailAccessor,
                  prevdd,
                  tauxTVA,
                  prevState.commande.coursDevise
                );
                computeTotals(prevState.commande);
                computeSubTotals(prevState.commande.commandeDetails);
              }),
              callBackFunction
            );
          }
        } else {
          // Nested property (separated by a '.')
          var accessorSplit = accessor.split(".");
          this.setState((prevState) => {
            return {
              commande: {
                ...prevState.commande,
                [accessorSplit[0]]: {
                  ...prevState.commande[accessorSplit[0]],
                  [accessorSplit[1]]: value,
                },
              },
            };
          }, callBackFunction);
        }
      } else {
        // Shallow Property of Devis

        // Special case (if we change the produit_interne or the type_mission, it might affect the calculette,
        // so at the next update, we will get the calculette from the back)
        if (accessor === "produit_Interne" || accessor === "type_Mission") {
          this.setState(
            (prevState) => ({
              commande: {
                ...prevState.commande,
                [accessor]: value,
              },
            }),
            callBackFunction
          );
        } else if (accessor == "num_Arc_Client") {
          this.setState((prevState) => ({
            commande: {
              ...prevState.commande,
              num_Arc_Client: value,
            },
          }));

          if (value?.length > 25 && this.state.numArcPasDejaVu) {
            this.setState({ numArcTropLong: true });
          } else if (value?.length < 25) {
            this.setState({ numArcTropLong: false, numArcPasDejaVu: true });
          }
        } else {
          // Normal case
          this.setState(
            (prevState) => ({
              commande: {
                ...prevState.commande,
                [accessor]: value,
              },
            }),
            callBackFunction
          );
        }
      }
    }
  }

  handleDelete() {
    return CommandeService.delete(this.state.commande).then(() => {
      this.props.history.push({ pathname: "/commandes" });
    });
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  handleUpdate() {
    return CommandeService.put(this.state.commande);
  }

  changeLinePosition(indexFrom, indexTo) {
    this.setState(
      produce((prevState) => {
        // Swap the two lines position in the array commande.commandeDetails
        arrayMoveMutable(
          prevState.commande.commandeDetails,
          indexFrom,
          indexTo
        );

        // Recalcompute the position of each line
        for (let i = 0; i < prevState.commande.commandeDetails.length; i++) {
          prevState.commande.commandeDetails[i].position = i;
        }

        // By moving a line, we may have to recompute the subTotals.
        computeSubTotals(prevState.commande.commandeDetails);
      })
    );
  }

  newLine(id, type, position) {
    // The new lines ids are negatives and unique (until the change gets applied, and then they got their id from the database).
    return {
      id: id,
      type: type,
      position: position,
      article: null,
      rubrique: null,
      description: null,
      quantite: 0,
      coefficient: 0,
      remise: null,
      prixAchatUnitaire: 0,
      prixAchatTotal: 0,
      prixVenteUnitaire: 0,
      prixVenteTotal: 0,
      prixVenteTotalTTC: 0,
      montantTVA: 0,
    };
  }

  addLineCommandeDetail(type, idCommandeDetail) {
    if (idCommandeDetail === undefined) {
      // Add a new empty line before all the lines
      this.setState(
        produce((prevState) => {
          // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
          let newLineId =
            Math.min(
              ...prevState.commande.commandeDetails.map((el) => el.id),
              0
            ) - 1;

          // To be at the top of the list, the new line position will be the minimum position - 1.
          let newPosition =
            Math.min(
              ...prevState.commande.commandeDetails.map((el) => el.position)
            ) - 1;

          // Unshift to put it at the front
          prevState.commande.commandeDetails.unshift(
            this.newLine(newLineId, type, newPosition)
          );
        })
      );
    } else {
      // Add a new empty line after the line whose id is given.
      this.setState(
        produce((prevState) => {
          // To keep the ids unique, if there is no other new line (with negative id), the new line id is -1, else, it's the minimum id - 1.
          let newLineId =
            Math.min(
              ...prevState.commande.commandeDetails.map((el) => el.id),
              0
            ) - 1;

          let selectedLine = prevState.commande.commandeDetails.findIndex(
            (el) => el.id === idCommandeDetail
          ); // The new line will take be placed after the selected line

          let newPosition =
            prevState.commande.commandeDetails[selectedLine].position + 1;

          for (
            // Then, we shift all the positions of the lines after the one to be inserted
            let i = selectedLine + 1;
            i < prevState.commande.commandeDetails.length;
            i++
          ) {
            prevState.commande.commandeDetails[i].position += 1;
          }

          prevState.commande.commandeDetails.splice(
            // Splice to insert at index
            selectedLine + 1,
            0,
            this.newLine(newLineId, type, newPosition)
          );
        })
      );
    }

    if (type == 2) {
      // If the new line is of type subTotal, we need to compute the subtotals after inserting the line.
      this.setState(
        produce((prevState) => {
          computeSubTotals(prevState.commande.commandeDetails);
        })
      );
    }
  }

  deleteLineCommandeDetail(idCommandeDetail) {
    this.setState(
      produce((prevState) => {
        prevState.commande.commandeDetails.splice(
          prevState.commande.commandeDetails.findIndex(
            (el) => el.id === idCommandeDetail
          ),
          1
        );
        computeTotals(prevState.commande);
        computeSubTotals(prevState.commande.commandeDetails);
      })
    );
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("CommandeOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
        <div className="ms-auto align-self-center">
          <div className="text-end d-flex">
            <>
              <DialogForm
                icon={faFileInvoiceDollar}
                tooltip="Générer une commande"
                classNameButton="btn btn-success"
                dialogTitle="Date prévisionnelle de remise du rapport"
                labelValidateButton="valider"
                setOpenDialog={this.setOpenDialogDelaiPrev}
                onValidate={() => {
                  let commande = this.state.commande;
                  this.setState({
                    affaire: {
                      commandes: [commande],
                      delaiRealisation: null,
                      datePrevisionnelleRemiseRapport: null,
                    },
                  });
                  CommandeService.put(this.state.commande).then(() =>
                    AffaireService.post(this.state.affaire).then((retour) => {
                      this.props.history.push("/affaires/" + retour.data.id);
                    })
                  );
                }}
                onClose={() => {}}
                body={
                  <>
                    <div id="PopupAffaire">
                      <div>
                        Une date prévisionnelle de remise du rapport est
                        nécessaire <br />
                      </div>

                      <Input
                        style={{ position: "relative" }}
                        label="Chargé d'Affaire"
                        accessor="charge_Affaire"
                        type="selectSearch"
                        value={this.state.commande.charge_Affaire}
                        service={PersonnelService.getAll}
                        optionFieldToDisplay={["nom", "prenom"]}
                        valueFieldToDisplay={["nom", "prenom"]}
                        showClearButton={false}
                        handleChange={(accessor, value) => {
                          this.setState(
                            produce((prevState) => {
                              prevState.commande.charge_Affaire = value;
                            })
                          );
                        }}
                        required={true}
                      />
                    </div>
                    <div>
                      {this.state.commande?.prduit_Interne?.isPresta ? (
                        <>
                          <Input
                            label="Délai previsionnel en semaine"
                            type="decimal"
                            value={this.state.affaire.delaiRealisation}
                            optionFieldToDisplay="designation"
                            valueFieldToDisplay="designation"
                            showClearButton={false}
                            handleBlur={(accessor, value) => {
                              this.setState(
                                produce((prevState) => {
                                  const now = new Date();
                                  prevState.affaire.delaiRealisation = value;
                                  now.setDate(
                                    now.getDate() + (value ? value * 7 : 0)
                                  );
                                  prevState.affaire.datePrevisionnelleRemiseRapport =
                                    now.toJSON();
                                  prevState.datePrevisionnelleRemiseRapportAff =
                                    now.toLocaleDateString("fr-FR");
                                })
                              );
                            }}
                            required={true}
                          />
                          <Input
                            label="Date estimée"
                            value={
                              this.state.datePrevisionnelleRemiseRapportAff
                            }
                            optionFieldToDisplay="designation"
                            valueFieldToDisplay="designation"
                            showClearButton={false}
                          />
                        </>
                      ) : null}
                    </div>
                  </>
                }
              />
            </>
          </div>
        </div>
      </div>
    );
  }

  generateDialogAssistance() {
    return (
      <DialogForm
        tooltip="Imprimer"
        classNameButton="btn btn-success"
        dialogTitle="Impression Assistance"
        labelValidateButton="Valider"
        setOpenDialog={this.setOpenDialogImpressionAss}
        validation={() => {
          if (this.state.impressionAssistance != null) return true;
          else {
            App.Toaster.current?.createToast({
              body: "Vous devez sélectionner un modèle",
              header: "Echec",
              type: "failure",
            });

            return false;
          }
        }}
        onValidate={() => {
          DevisService.printAssistance(
            this.state.impressionAssistance.designation,
            this.state.devis.id
          ).then((res) => {
            var blob = new Blob([res?.data], {
              type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            FileSaver.saveAs(
              blob,
              "Assistance_" + this.state.devis.nom_Devis + ".docx"
            );
          });
        }}
        onClose={() => {}}
        body={
          <div id="PopupCommande">
            <div>
              Veuillez choisir un modèle d'impression <br />
            </div>

            <Input
              label="Modèle"
              accessor="impressionAssistance"
              type="selectSearch"
              value={this.state.impressionAssistance}
              valueFieldToDisplay="designation"
              optionFieldToDisplay="designation"
              options={[
                {
                  id: 3,
                  designation: "Panda",
                },
              ]}
              functionAppliedToValue={(value) => {
                if (value) return value.designation;
                return null;
              }}
              handleChange={(acc, value) =>
                this.setState({ impressionAssistance: value })
              }
              required={true}
            />
          </div>
        }
      />
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <InformationsCommande
            commande={this.state.commande}
            stateFieldNameToUpdate="commande"
            handleChange={this.handleChange}
            service={CommandeService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
          ></InformationsCommande>
        );
      case "Détails":
        return (
          <Details
            commande={this.state.commande}
            stateFieldNameToUpdate="commande"
            handleChange={this.handleChange}
            changeLinePosition={this.changeLinePosition}
            service={CommandeService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
            addLineCommandeDetail={this.addLineCommandeDetail}
            deleteLineCommandeDetail={this.deleteLineCommandeDetail}
          ></Details>
        );
      case "Logistique":
        return (
          <Logistique
            commande={this.state.commande}
            stateFieldNameToUpdate="commande"
            handleChange={this.handleChange}
            service={CommandeService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          ></Logistique>
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const isCommandeEmpty = !Object.entries(this.state.commande).length;
    return (
      <>
        <Helmet>
          <title>
            {"Commande : "
              .concat(this.state.commande.reference)
              .concat(" | ")
              .concat(this.state.commande.nom_Commande ?? "")}
          </title>
        </Helmet>
        <AlertDialog
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title="Il manque des champs obligatoires"
          body={
            <>
              <div>Il manque des champs à remplir pour créer un devis : </div>
              <div> {this.getChampsObligatoireVide().map((e) => e + ", ")}</div>
            </>
          }
          valider="Ok"
          handleClose={() => this.setState({ open: false })}
        />
        <AlertDialog
          open={this.state.numArcTropLong && this.state.numArcPasDejaVu}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title=""
          body={
            <>
              <div>Le champ Num ARC Client sera tronqué :</div>
              <div>{this.state.commande?.num_Arc_Client?.substring(0, 25)}</div>
            </>
          }
          valider="Ok"
          handleClose={() => this.setState({ numArcPasDejaVu: false })}
        />
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {"Commande : "
                  .concat(this.state.commande.reference)
                  .concat(" | ")
                  .concat(this.state.commande.nom_Commande ?? "")}
              </h4>
              <div className="d-flex">
                <Link to="/commandes" className="btn btn-primary nowrap ms-1">
                  Base Commande
                </Link>
                {!this.state.commande?.affaire ? (
                  <ButtonIcon
                    icon={faBalanceScale}
                    className="btn btn-success  text-light ms-1"
                    type="submit"
                    onClick={() => {
                      if (this.getChampsObligatoireVide()?.length == 0) {
                        this.state.setOpenDialogDelaiPrevFunc();
                      } else {
                        this.setState({ open: true });
                      }
                    }}
                    tooltip="Créer une nouvelle affaire"
                  ></ButtonIcon>
                ) : null}
                <ButtonIcon
                  icon={faClipboardList}
                  className="btn btn-success  text-light ms-1"
                  tooltip="Impression liste de colisage"
                  onClick={() => {
                    CommandeService.print(this.state.commande.id).then(
                      (res) => {
                        var blob = new Blob([res?.data], {
                          type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        FileSaver.saveAs(
                          blob,
                          this.state.commande.reference + ".docx"
                        );
                      }
                    );
                  }}
                />
                {this.state.commande.contrat ? (
                  <ButtonIcon
                    icon={faPrint}
                    className="btn btn-success text-light ms-1"
                    tooltip="Impression contrat"
                    onClick={() => {
                      if (
                        this.state.commande.contrat.categorie?.designation ==
                          "ASS" ||
                        this.state.commande.contrat.pack?.designation ==
                          "Assistance"
                      ) {
                        ContratService.printAss(
                          this.state.commande.contrat.id
                        ).then((res) => {
                          var blob = new Blob([res?.data], {
                            type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          });
                          FileSaver.saveAs(
                            blob,
                            this.state.commande.contrat.numEtude + ".docx"
                          );
                        });
                      } else {
                        ContratService.print(
                          this.state.commande.contrat.id
                        ).then((res) => {
                          var blob = new Blob([res?.data], {
                            type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          });
                          FileSaver.saveAs(
                            blob,
                            this.state.commande.contrat.numEtude + ".docx"
                          );
                        });
                      }
                    }}
                  />
                ) : null}
                {App.RightsGuard.current?.hasRight("Commande", "Suppression") ==
                "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="form-control btn btn-danger text-light ms-1"
                    tooltip="Supprimer cette commande"
                    alertTitle=" Suppression"
                    alertBody={
                      <div>
                        Souhaitez-vous réellement supprimer cette commande ?
                        <br />
                        (il sera supprimé pour tous ses clients)
                      </div>
                    }
                    alertButtonValidationText="Oui, je veux supprimer."
                  />
                ) : null}
                {/* {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )} */}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={this.state.commande?.doPieceDocSage != null}
              content={"Cette commande n'existe pas dans Sage."}
            />
            <WarningBar
              active={this.state.commande?.affaire != null}
              content={
                "La commande n'a pas d'affaire. Vérifiez que l'affaire sensée être liée existe bien dans Sage."
              }
            />
            {!isCommandeEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheCommande.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheCommande };
