import http from "http-common";

const ConstanteService = class {
  /**
   * Fonction permettant de récuperer toutes les actions en cours
   * @returns la liste de toutes les actions en cours
   */
  static getAll() {
    return http.get("/Constantes");
  }
};

export { ConstanteService };
