import { faCheck, faPen, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Input } from "_components/Input";

const { Box, ButtonIcon } = require("_components");
const { useState } = require("react");

const LigneContrat = (props) => {
  return (
    <tr className="ligneFournisseurContrat">
      <td className="col1 background-color">
        <Input
          accessor={"" + props.contrat.id + ".libelle"}
          value={props.contrat.libelle}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col3 background-color">
        <Input
          accessor={"" + props.contrat.id + ".dateFin"}
          value={props.contrat.dateFin}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          disabled={!props.editing}
        />
      </td>
      <td className="col4 background-color" style={{ width: "55px" }}>
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          onClick={() => {
            props?.deleteLineContrat(props.contrat.id);
          }}
          className="btn btn-danger deleteLineContrat"
          style={!props.editing ? { display: "none" } : {}}
        />
      </td>
    </tr>
  );
};

const BoxContrats = (props) => {
    const [savedFournisseur, setSavedFournisseur] = useState({});

    const [editingContrat, setEditingContrat] = useState(false);
    const [contrats, setContrats] = useState([]);
  
    const handleClickEdition = () => {
      setSavedFournisseur({...props.fournisseur});
      props.handleEditing(true);
      setEditingContrat(true);
      if (props.fournisseur.contrats == null) {
        setContrats([]);
      } else {
        setContrats(props.fournisseur.contrats.map(e => {return {...e};}));
      }
    };
  
    const handleClickUndo = () => {
      props.handleChange("fournisseur", savedFournisseur);
      props.handleEditing(false);
      setEditingContrat(false);
      setContrats([]);
    };
  
    const handleClickValidate = () => {
      props.handleChange("contrats", contrats);
      
      props.handleEditing(false);
      setEditingContrat(false);
      setContrats([]);
    };



    const addLineContrat = () => {
        let newLineId = Math.min(...contrats.map((el) => el.id), 0) - 1;
    
        let newContrat = {
          id: newLineId,
          libelle: "",
          dateFin: null,
        };
        let contrattmp = contrats.map(e => {return {...e};});
        setContrats([...contrattmp, {...newContrat}]);
    };
    
    const deleteLineContrat = (contratId) => {
        let contrattmp = contrats.map(e => {return {...e};});
        contrattmp.splice(
            contrattmp.findIndex((el) => el.id == contratId),
            1
        );
        console.log(contrats, contrattmp);
        setContrats([...contrattmp]);
    };
    
    const handleChangeContrat = (accessor, value) => {
        // Format enfantPersonnel.id.accessor
        let accessorSplit = accessor.split(".");
        var id = accessorSplit[0];
        var realAccessor = accessorSplit[1];

        let contrattmp = contrats.map(e => {return {...e};});

        let contratToUpdate = contrattmp.find((el) => el.id == id);
        contratToUpdate[realAccessor] = value;

        contrattmp.splice(
            contrattmp.findIndex((el) => el.id == id),
            1,
            contratToUpdate
        );

        setContrats(contrattmp);
    };

    return (
    <Box
        header={
            <>
            <div className="col-6 align-self-center py-2">
                <div>Contrat</div>
            </div>
            <div className="col-6 text-end py-2">
                <ButtonIcon
                icon={faPlus}
                iconSize="sm"
                onClick={() => addLineContrat?.()}
                className="btn btn-success addEnfant"
                style={!editingContrat ? { display: "none" } : {}}
                />
                <ButtonIcon
                icon={faCheck}
                className="btn btn-success text-light ms-1"
                style={
                    !editingContrat ? { display: "none" } : { width: "42px" }
                }
                onClick={handleClickValidate}
                />
                <ButtonIcon
                icon={faTimes}
                className="btn btn-danger text-light ms-1"
                onClick={handleClickUndo}
                style={
                    !editingContrat ? { display: "none" } : { width: "42px" }
                }
                />
                <ButtonIcon
                icon={faPen}
                className="btn btn-secondary text-light ms-1"
                onClick={handleClickEdition}
                style={
                    props.editing ? { display: "none" } : { width: "42px" }
                }
                />
            </div>
            </>
        }
        body={
            <>
            <table
                className={
                "text-uppercase table table-sm " +
                (editingContrat
                    ? contrats.length > 0
                    ? " "
                    : " table-bordered"
                    : props.fournisseur.contrat?.length > 0
                    ? " "
                    : " table-bordered")
                }
            >
                <thead
                className={
                    "sticky-top" +
                    (editingContrat
                    ? contrats.length == 0
                        ? " theadBorderRadius"
                        : ""
                    : props.fournisseur.contrat?.length == 0
                    ? " theadBorderRadius"
                    : "")
                }
                >
                <tr>
                    <th>Libellé</th>
                    <th>Date de fin</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {editingContrat
                    ? contrats
                        ?.slice()
                        .map((contrat) => (
                        <LigneContrat
                            key={contrat.id}
                            contrat={contrat}
                            handleChange={handleChangeContrat}
                            editing={editingContrat}
                            deleteLineContrat={deleteLineContrat}
                        />
                        ))
                    : props.fournisseur.contrats
                        ?.slice()
                        .map((contrat) => (
                        <LigneContrat
                            key={contrat.id}
                            contrat={contrat}
                            handleChange={handleChangeContrat}
                            editing={editingContrat}
                            deleteLineContrat={deleteLineContrat}
                        />
                        ))}
                </tbody>
            </table>
            </>
        }
    />);
};

export {BoxContrats};