import React, { useEffect, useState } from "react";

import { Box, BoxInformationsInline, WarningBar } from "_components";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Input } from "_components/Input";
import { App } from "App";
import { FournisseurService } from "_services";
import { AccordionParAnnee } from "./SubTable/AccordionParAnnee";

const Statistiques = (props) => {
  const [anneeStatistique, setAnneeStatistique] = useState(2);
  const [statistique, setStatistique] = useState({});

  const reloadStats = () => {
    FournisseurService.getStatistiques(props.fournisseur.id, anneeStatistique)
      .then((res) => setStatistique(res.data))
      .catch((e) => console.log(e));
  };

  useEffect(() => reloadStats(), []);
  useEffect(() => reloadStats(), [anneeStatistique]);

  const right = App.RightsGuard?.current?.hasRight("Client", "Stat");
  if (right == "RW" || right == "RO") {
    // On crée la hierarchie des documents à afficher :
    //    Factures : Les factures (plusieurs en cas d'avoir) associées à chaque commande.
    // TODO : différencier les factures d'avoir des factures classiques.
    if (props.loadingSynthese)
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
        </div>
      );

    var arrFacturesIds = [];

    var arrFacturesSansCommandes = statistique?.factures?.filter(
      (f) => !arrFacturesIds.includes(f.num_Facture)
    );

    arrFacturesSansCommandes?.sort(
      (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
    );

    var arrDocumentParAnnee = {};
    arrFacturesSansCommandes?.forEach((facture) =>
      arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()]
        ? arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()]
            ?.factures
          ? arrDocumentParAnnee[
              new Date(facture.dateCreation).getFullYear()
            ].factures.push(facture)
          : (arrDocumentParAnnee[
              new Date(facture.dateCreation).getFullYear()
            ].factures = [facture])
        : (arrDocumentParAnnee[new Date(facture.dateCreation).getFullYear()] = {
            annee: new Date(facture.dateCreation).getFullYear(),
            factures: [facture],
          })
    );

    let bloc3 = [
      {
        label: "CA Sol Solution",
        accessor: "caSolSol",
        functionAppliedToValue: () => {
          return "Je prends selon le nombre d'année ou je calcule pour le tout ?";
        },
        colSize: 6,
      },
      {
        label: "CA Fournisseur",
        accessor: "caFournisseur",
        type: "decimalTable",
        handleBlur: props.handleChange,
        functionAppliedToValue: (value) => {
          return value;
        },
        colSize: 6,
      },

      {
        label: "Dépendance financière",
        accessor: "dependanceFinanciere",
        functionAppliedToValue: () => {
          return "ça depend du calcul du CA";
        },
      },
    ];

    return (
      <>
        <div style={{ display: "flex", marginBottom: "15px" }}>
          <div className="d-flex flex-row align-items-center me-2">
            <div
              style={{
                flexShrink: "0",
              }}
            >
              Nombre d'années affichées :
            </div>
            <Input
              type="decimalTable"
              numberOfDecimals={0}
              handleBlur={(acc, val) => setAnneeStatistique(val)}
              accessor={"anneeStatistique"}
              value={anneeStatistique}
              tooltip={
                <div>
                  Attention, plus le nombre est élevé, plus le temps de
                  chargement sera long.
                </div>
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <BoxInformationsInline
              title="Statistique"
              datas={bloc3}
              state={props.fournisseur}
              stateFieldNameToUpdate={props.stateFieldNameToUpdate}
              service={props.service}
              handleChange={props.handleChange}
              handleEditing={props.handleEditing}
              editing={props.editing}
              handleUpdate={props.handleUpdate}
            />
          </div>
          <div className="col-md-12 col-lg-12">
            <Box
              noPaddingBottom={true}
              header={
                <div className="col-12 align-self-center py-2">
                  <div>Synthèse</div>
                </div>
              }
              body={
                <>
                  <table className="table table-sm table-striped table-bordered">
                    <thead
                      className={
                        "sticky-top" +
                        (statistique?.synthese?.length == 0
                          ? " theadBorderRadius"
                          : "")
                      }
                    >
                      <tr>
                        <th>Année</th>
                        <th>CA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statistique?.synthese?.map((d, index) => {
                        return (
                          <tr key={index}>
                            <td>{d.annee}</td>
                            <td>{d.sommeFactures}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              }
            />
          </div>

          <div className="col-12">
            <Box
              noPaddingBottom={true}
              header={
                <div className="col-12 align-self-center py-2">
                  <div>Documents</div>
                </div>
              }
              body={
                <table
                  id="tableStat"
                  className="table table-sm table-striped table-bordered m-0"
                >
                  <thead
                    className={
                      "sticky-top" +
                      (Object.entries(arrDocumentParAnnee)?.length == 0
                        ? " theadBorderRadius"
                        : "")
                    }
                  >
                    <tr>
                      <th>Année</th>
                      <th>Document</th>
                      <th>Date de création</th>
                      <th>Prix HT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(arrDocumentParAnnee)
                      .sort((a, b) => {
                        return b[0] - a[0];
                      })
                      .map(function ([key, value], index) {
                        return (
                          <AccordionParAnnee
                            key={key}
                            annee={key}
                            isInitiallyOpen={index == 0}
                            factures={value.factures}
                          />
                        );
                      })}
                  </tbody>
                </table>
              }
            />
          </div>
        </div>
      </>
    );
  } else
    return (
      <>
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      </>
    );
};

export { Statistiques };
