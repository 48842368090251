import React from "react";
import { App } from "App";
import { ToLocaleDateString } from "_utils";
import { Box, WarningBar } from "_components";
import { formatValueDecimal } from "_utils/DecimalFunctions.js";
import { AffaireService } from "_services";
import { Input } from "_components/Input";

class Heures extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.calculRubrique = this.calculRubrique.bind(this);
    this.loadHeure = this.loadHeure.bind(this);
  }

  componentDidMount() {
    this.loadHeure();
  }

  loadHeure() {
    AffaireService.getHeures({
      idAffaire: this.props.affaire.id,
      personnelsIds: this.state.personnelsIds,
      periode: this.state.period,
    }).then((res) => {
      this.setState({
        heures: res.data,
      });
    });
  }

  calculRubrique(stringRubrique) {
    let x = { rubrique: stringRubrique };
    x.nombre = 0;
    x.nbLignes = 0;
    this.state.heures
      .filter((e) => e.rubrique?.designation == stringRubrique)
      .map((h) => {
        x.nombre = x.nombre + (h.nombre ?? 0);
        x.nbLignes++;
      });

    return x;
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Affaire", "Heures");

    if (right == "RW" || right == "RO") {
      var heures = this.state.heures?.slice();

      if (!heures) heures = [];

      heures = heures
        .slice()
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      let rubriques = new Set();

      heures
        .filter((e) => e.rubrique != null)
        .map((h) => rubriques.add(h.rubrique.designation));
      const informationAffaire = [];
      rubriques.forEach((r) => informationAffaire.push(this.calculRubrique(r)));

      return (
        <>
          <Box
            header={
              <>
                <div className="col-12 align-self-center py-2 d-flex flex-row justify-content-between">
                  <div>Filtres</div>
                </div>
              </>
            }
            body={
              <>
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <th className="solwayFont">Personnel</th>
                      <th className="solwayFont">Période</th>
                    </tr>
                    <tr>
                      <td>
                        <Input
                          value={this.state.personnelsIds}
                          accessor="personnelsIds"
                          type="multipleSelectSearch"
                          options={this.props.affaire.personnels}
                          valueFieldToDisplay={["nom", "prenom"]}
                          optionFieldToDisplay={["nom", "prenom"]}
                          optionFieldToReturn={"id"}
                          handleChange={(acc, val) =>
                            this.setState(
                              { personnelsIds: val },
                              this.loadHeure
                            )
                          }
                        />
                      </td>

                      <td>
                        <Input
                          value={this.state.period}
                          accessor="period"
                          type="period"
                          handleChange={(acc, val) =>
                            this.setState({ period: val }, this.loadHeure)
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            }
            rowClass="px-2"
          />

          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Résumé heure</div>
              </div>
            }
            body={
              <table className="table table-sm table-striped table-bordered">
                <thead
                  className={
                    "sticky-top" +
                    (informationAffaire?.length == 0
                      ? " theadBorderRadius"
                      : "")
                  }
                >
                  <tr>
                    <th>Rubrique</th>
                    <th>Nombre</th>
                    <th>Ligne</th>
                  </tr>
                </thead>
                <tbody>
                  {informationAffaire.map((d, index) => {
                    return (
                      <tr key={index}>
                        <td className="tableIndent1">{d.rubrique}</td>
                        <td className="tableIndent1">
                          {formatValueDecimal(d.nombre)}
                        </td>
                        <td className="tableIndent1">
                          {formatValueDecimal(d.nbLignes)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          />
          <Box
            noPaddingBottom={true}
            header={
              <div className="col-12 align-self-center py-2">
                <div>Heures</div>
              </div>
            }
            body={
              <table className="table table-sm table-striped table-bordered">
                <thead
                  className={
                    "sticky-top" +
                    (heures?.length == 0 ? " theadBorderRadius" : "")
                  }
                >
                  <tr>
                    <th>Libellé</th>
                    <th>Rubrique</th>
                    <th>Personnel</th>
                    <th>Date</th>
                    <th>Nombre</th>
                  </tr>
                </thead>
                <tbody>
                  {heures.map((d) => {
                    return (
                      <tr key={d.id}>
                        <td className="tableIndent1">{d.libelle}</td>
                        <td className="tableIndent1">
                          {d.rubrique?.designation ?? "-"}
                        </td>
                        <td className="tableIndent1">
                          {d.personnel?.initiales ?? "-"}
                        </td>
                        <td className="tableIndent1">
                          {d.date != null ? ToLocaleDateString(d.date) : null}
                        </td>
                        <td className="tableIndent1">
                          {formatValueDecimal(d.nombre)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
          />
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

export { Heures };
