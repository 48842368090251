import http from "http-common";

const ChantierService = class {
  /**
   * Fonction permettant de récuperer tous les chantiers avec une possibilité de filtre via params
   * @param {*} params, paramètres à utiliser pour la requête (page, nombre d'éléments par page et filtres sur les champs)
   * @returns une liste des chantiers respectant les critères de params et le nombre de page pour ces critères
   */
  static getAllViaPost(params) {
    return http.post("/chantier/get", params);
  }

  /**
   * Fonction permettant de créer un nouveau chantier pour un chantier donné
   * @param {*} chantier, le chantier à ajouter
   * @returns le chantier crée avec son id
   */
  static post(chantier) {
    return http.post("/chantier", chantier);
  }

  /**
   * Fonction permettant de mettre à jour un consultation
   * @param {*} chantier, le chantier à mettre à jour
   * @returns
   */
  static put(chantier) {
    return http.put("/chantier/", chantier);
  }

  /**
   * Fonction permettant de récuperer un consultation via son id
   * @param {*} id, id de du consultation à récupérer
   * @returns le consultation souhaité
   */
  static getByIdAffaire(id) {
    return http.get("/chantier/getByAffaire/" + id);
  }

  static getOneById(id) {
    return http.get("/chantier/getOneById/" + id);
  }

  static getMesChantiers() {
    return http.get("/chantier/mesChantiers");
  }

  /**
   * @returns un fichier.
   */
  static print(idDevis) {
    return http({
      url: "/chantier/impressionPochette/" + idDevis,
      method: "GET",
      responseType: "blob",
    });
  }

  static ExtractViaPost(payload) {
    return http({
      url: "/chantier/extraction",
      method: "POST",
      responseType: "blob",
      data: payload,
    });
  }
};

export { ChantierService };
