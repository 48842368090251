import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Box, ButtonIcon, DialogForm } from "_components";
import { Input } from "_components/Input";
import {
  ActionsTacheService,
  FamilleAffaireService,
  FournisseurService,
  ProduitInterneService,
} from "_services";
import { ToLocaleDateString } from "_utils";
import { App } from "App";
import React, { useEffect, useState } from "react";

const Historique = (props) => {
  const [historiques, setHistoriques] = useState([]);
  const [newHisto, setNewHisto] = useState({});
  const [openForm, setOpenForm] = useState(() => {});
  const GetHistorique = () => {
    FournisseurService.getHistoriques(props.fournisseur.id).then((res) =>
      setHistoriques(res.data)
    );
  };

  useEffect(() => GetHistorique(), []);

  const renderDialog = () => {
    return (
      <div>
        <DialogForm
          tooltip="Ajouter un historique"
          classNameButton="btn btn-success"
          dialogTitle="Ajouter un historique"
          labelValidateButton="Valider"
          setOpenDialog={(callback) => setOpenForm(() => callback)}
          onValidate={() => {
            FournisseurService.postHistorique(
              props.fournisseur.id,
              newHisto
            ).then(() => {
              GetHistorique();
              setNewHisto({});
            });
          }}
          onClose={() => {}}
          body={
            <div
              id="PopupCommande"
              className={"col-lg-12 row"}
              style={{ minWidth: "1000px" }}
            >
              <div className={"col-lg-6"}>
                <Input
                  value={newHisto.action}
                  label="Action"
                  accessor="action"
                  type="selectSearch"
                  service={() =>
                    ActionsTacheService.getAll({
                      processId: newHisto.process?.id,
                    })
                  }
                  showClearButton={false}
                  optionFieldToDisplay={["designation"]}
                  optionFieldToReturn={"designation"}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    let histo = { ...newHisto };
                    histo.action = value;
                    setNewHisto(histo);
                  }}
                />
              </div>
              <div className={"col-lg-6"}>
                <Input
                  value={newHisto.produitInterne}
                  type="selectSearch"
                  accessor="produitInterne"
                  label="Produit Interne"
                  service={ProduitInterneService.getAll}
                  showClearButton={false}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    let histo = { ...newHisto };
                    histo.produitInterne = value;
                    setNewHisto(histo);
                  }}
                />
                <Input
                  value={newHisto.familleAffaire}
                  accessor="familleAffaire"
                  type="selectSearch"
                  label="Famille d'affaire"
                  service={FamilleAffaireService.getAll}
                  showClearButton={false}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    let histo = { ...newHisto };
                    histo.familleAffaire = value;
                    setNewHisto(histo);
                  }}
                />
                <Input
                  value={newHisto.contact}
                  accessor="contact"
                  type="selectSearch"
                  label="Contact"
                  options={props.fournisseur.contacts}
                  showClearButton={false}
                  optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  handleChange={(accessor, value) => {
                    let histo = { ...newHisto };
                    histo.contact = value;
                    setNewHisto(histo);
                  }}
                />
              </div>
              <div className={"col-lg-12"}>
                <Input
                  value={newHisto.description}
                  accessor="description"
                  type="textArea"
                  label="Description"
                  handleChange={(accessor, value) => {
                    let histo = { ...newHisto };
                    histo.description = value;
                    setNewHisto(histo);
                  }}
                />
              </div>
            </div>
          }
        />
      </div>
    );
  };

  let right = App.RightsGuard?.current?.hasRight("Client", "Historique");
  if (right == "RW" || right == "RO") {
    return (
      <>
        {renderDialog()}

        <Box
          noPaddingBottom={true}
          header={
            <div className="row text-align-center">
              <div className="col-6 py-2">Historique</div>

              <div className="col-6 text-end py-2">
                <ButtonIcon
                  smallText=""
                  icon={faPlus}
                  iconSize="sm"
                  onClick={() => {
                    openForm();
                  }}
                  className="btn btn-success"
                />
              </div>
            </div>
          }
          body={
            <div
              className="lock-header "
              style={{
                padding: "0 0 0 0 ",
              }}
            >
              <table className="tableHistorique table table-striped table-bordered table-sm">
                <thead
                  className={
                    "sticky-top" +
                    (historiques?.length == 0 ? " theadBorderRadius" : "")
                  }
                >
                  <tr>
                    <th className="text-nowrap ps-2">Date</th>
                    <th className="text-nowrap ps-2">Action</th>
                    <th className="text-nowrap ps-2">Par</th>
                    <th className="text-nowrap ps-2">Produit Interne</th>
                    <th className="text-nowrap ps-2">Famille d'affaire</th>
                    <th className="text-nowrap ps-2">Contact</th>
                    <th className="text-nowrap ps-2">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {historiques
                    ?.slice()
                    ?.sort(
                      (a, b) =>
                        new Date(b.dateCreation) - new Date(a.dateCreation)
                    )
                    .map((historique) => {
                      return (
                        <tr key={historique.id}>
                          <td className="ps-2">
                            {ToLocaleDateString(historique.dateCreation)}
                          </td>

                          <td className="ps-2">{historique.action ?? "-"}</td>
                          <td className="ps-2">
                            {historique.finishedBy?.initiales ?? "-"}
                          </td>

                          <td className="ps-2">
                            {historique.produitInterne?.designation ?? "-"}
                          </td>
                          <td className="ps-2">
                            {historique.familleAffaire?.designation ?? "-"}
                          </td>
                          <td className="ps-2">
                            {historique.contact ? (
                              <a
                                href={"/contacts/" + historique.contact.id}
                                target="_blank"
                              >
                                {historique.contact.prenom_Contact +
                                  " " +
                                  historique.contact.nom_Contact}
                              </a>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="ps-2 keepWhiteSpace">
                            {historique.description}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          }
        />
      </>
    );
  } else
    return (
      <>
        <WarningBar
          active={false}
          content={"Vous n'avez pas le droit de voir cette page"}
        />
      </>
    );
};

export { Historique };
