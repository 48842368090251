import React, { useEffect, useState } from "react";

import { Box, BoxInformationsInline } from "_components";
import { FournisseurCritereService } from "_services";

import {
  ResponsiveContainer,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  CartesianGrid,
} from "recharts";

const Score = (props) => {
  let [criteres, setCriteres] = useState([]);

  useEffect(() => {
    FournisseurCritereService.getAll().then((e) => setCriteres(e.data));
  }, []);

  let tarifaire = [];
  let logistique = [];
  let qualite = [];
  let technique = [];
  let durabilite = [];
  let geographique = [];

  if (criteres) {
    tarifaire = criteres.filter((e) => e.categorie == "tarifaire");
    logistique = criteres.filter((e) => e.categorie == "logistique");
    qualite = criteres.filter((e) => e.categorie == "qualité");
    technique = criteres.filter((e) => e.categorie == "technique");
    durabilite = criteres.filter((e) => e.categorie == "durabilité");
    geographique = criteres.filter((e) => e.categorie == "géographique");
  }

  const bloc3 = [
    {
      label: "Critère tarifaire",
      accessor: "critereTarifaire",
      functionAppliedToValue: (value) => {
        return value ? value.designation : null;
      },
      colSize: 4,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "selectSearch",
      options: tarifaire,
    },

    {
      label: "Critère logistique",
      accessor: "critereLogistique",
      functionAppliedToValue: (value) => {
        return value ? value.designation : null;
      },
      colSize: 4,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "selectSearch",
      options: logistique,
    },
    {
      label: "Critère qualité",
      accessor: "critereQualite",
      functionAppliedToValue: (value) => {
        return value ? value.designation : null;
      },
      colSize: 4,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "selectSearch",
      options: qualite,
    },
    {
      label: "Critère technique",
      accessor: "critereTechnique",
      functionAppliedToValue: (value) => {
        return value ? value.designation : null;
      },
      colSize: 4,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "selectSearch",
      options: technique,
    },
    {
      label: "Critère durabilité",
      accessor: "critereDurabilite",
      functionAppliedToValue: (value) => {
        return value ? value.designation : null;
      },
      colSize: 4,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "selectSearch",
      options: durabilite,
    },
    {
      label: "Critère géographique",
      accessor: "critereGeographique",
      functionAppliedToValue: (value) => {
        return value ? value.designation : null;
      },
      colSize: 4,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "selectSearch",
      options: geographique,
    },
    {
      label: "Score total",
      accessor: "scoreCritere",
      functionAppliedToValue: (value) => {
        return "" + value + "/12";
      },
    },
  ];

  const data = [];
  props.fournisseur.historiqueScore?.forEach((s) => {
    var dataPoint = {
      name: new Date(s.date)?.getFullYear(),
      tarifaire: s.critereTarifaire,
      logistique: s.critereLogistique,
      qualite: s.critereQualite,
      technique: s.critereTechnique,
      durabilite: s.critereDurabilite,
      geographique: s.critereGeographique,
    };

    data.push(dataPoint);
  });
  data.sort((a, b) => a.name - b.name);

  return (
    <>
      <div className="row ficheMateriel">
        <div className="col-12 ">
          <BoxInformationsInline
            title="Scores"
            datas={bloc3}
            state={props.fournisseur}
            stateFieldNameToUpdate={props.stateFieldNameToUpdate}
            service={props.service}
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
          />
        </div>
        <div className="col-12 boxGrapheScore">
          <Box
            header={
              <div className="col-12 align-self-center py-2">
                <div>Historique</div>
              </div>
            }
            body={
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  maxBarSize={50}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis domain={[0, 12]} />
                  <Tooltip />
                  <Legend />
                  {/* <Bar dataKey="tarifaire" stackId="a" fill="#d9b300" />
                  <Bar dataKey="logistique" stackId="a" fill="#fdbf6f" />
                  <Bar dataKey="qualite" stackId="a" fill="#fb9a99" />
                  <Bar dataKey="technique" stackId="a" fill="#cab2d6" />
                  <Bar dataKey="durabilite" stackId="a" fill="#a6cee3" />
                  <Bar dataKey="geographique" stackId="a" fill="#b2df8a" /> */}

                  <Bar dataKey="tarifaire" stackId="a" fill="#d9b300" />
                  <Bar dataKey="logistique" stackId="a" fill="#e66c37" />
                  <Bar dataKey="qualite" stackId="a" fill="#e044a7" />
                  <Bar dataKey="technique" stackId="a" fill="#6b007b" />
                  <Bar dataKey="durabilite" stackId="a" fill="#118dff" />
                  <Bar dataKey="geographique" stackId="a" fill="#107c10" />
                </BarChart>
              </ResponsiveContainer>
            }
          />
        </div>
      </div>
    </>
  );
};

export { Score };
