import React from "react";

import { BoxInformationsInline } from "_components";
import {
  FournisseurDomaineService,
  FournisseurQualificationService,
  LangueService,
} from "_services";

const General = (props) => {
  const bloc1 = [
    {
      label: "Qualification",
      accessor: "qualification",
      functionAppliedToValue: (value) => {
        return value ? value.designation : null;
      },
      colSize: 4,
      service: FournisseurQualificationService.getAll,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "selectSearch",
    },

    {
      label: "Site web",
      accessor: "siteWeb",
      colSize: 4,
      type: "text",
    },

    {
      label: "Langue",
      accessor: "langue",
      functionAppliedToValue: (value) => {
        return value ? value.langage : null;
      },
      colSize: 4,
      service: LangueService.getAll,
      optionFieldToDisplay: "langage",
      valueFieldToDisplay: "langage",
      type: "selectSearch",
    },

    {
      label: "Domaines",
      accessor: "domaines",
      functionAppliedToValue: (value) => {
        return value && value.length > 0 ? (
          <>{value?.map((e) => e.designation).join(", ")}</>
        ) : (
          "-"
        );
      },
      colSize: 4,
      service: FournisseurDomaineService.getAll,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "multipleSelectSearch",
    },

    {
      label: "Actif",
      accessor: "actif",
      colSize: 4,
      type: "checkbox",
      functionAppliedToValue: (value) => {
        return value ? "Oui" : "Non";
      },
    },
  ];
  const bloc2 = [
    {
      label: "Nom société",
      accessor: "nom",
      colSize: 4,
    },
    {
      label: "Compte tier",
      accessor: "compteTier",
      colSize: 4,
    },
    {
      label: "Siret",
      accessor: "siret",
      colSize: 4,
    },
    {
      label: "Adresse",
      accessor: "adresse",
      colSize: 4,
      functionAppliedToValue: () => {
        if (props.fournisseur.adresse)
          return (
            <>
              {props.fournisseur.adresse?.intitule ?? ""}
              <br />
              {props.fournisseur.adresse?.voie ?? ""}
              <br />
              {props.fournisseur.adresse?.complement ?? ""}
              <br />
              {(props.fournisseur.adresse?.cp ?? "") +
                " " +
                props.fournisseur.adresse?.ville ?? ""}
              <br />
              {props.fournisseur.adresse?.pays?.nom_Fr ?? ""}
            </>
          );
        else return <>-</>;
      },
    },
  ];

  return (
    <>
      <div className="row ficheMateriel">
        <div className="col-12 ">
          <BoxInformationsInline
            title="Informations"
            datas={bloc1}
            state={props.fournisseur}
            stateFieldNameToUpdate={props.stateFieldNameToUpdate}
            service={props.service}
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
          />
        </div>
        <div className="col-12 ">
          <BoxInformationsInline
            title="Informations Sage"
            datas={bloc2}
            state={props.fournisseur}
            NoEdition={true}
          />
        </div>
      </div>
    </>
  );
};

export { General };
