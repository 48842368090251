import React, { useState } from "react";

import { App } from "App";
import { BoxContact, ButtonIcon } from "_components";
import { ContactService } from "_services";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Input } from "_components/Input";

const Contacts = (props) => {
  const [recherche, setRecherche] = useState("");

  const CartesContact = () => {
    const right = App.RightsGuard?.current?.hasRight("Client", "Contacts");

    let contactsToDisplay = props.fournisseur?.contacts?.map((contact) => (
      <div className="col-lg-4 col-md-12" key={contact.id} id={contact.id}>
        <BoxContact
          contact={contact}
          clientId={props.fournisseur.id}
          updateFunction={props.updateContact}
          history={props.history}
          NoEdition={right == "RO"}
        />
      </div>
    ));

    return contactsToDisplay;
  };

  const handleSelectContact = (acc, selectOption) => {
    if (selectOption)
      ContactService.linkFournisseur(selectOption, props.fournisseur.id)
        .then(() => {
          props.reload();
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const handleAddContact = () => {
    props.history.push({
      pathname: "/contacts/add",
      state: { fournisseur: props.fournisseur },
    });
  };

  return (
    <>
      <div
        className="d-flex mt-2 justify-content-between"
        style={{ marginBottom: "10px" }}
      >
        <div
          className="input-group"
          style={{ width: "500px", flexShrink: "0" }}
        >
          <input
            name="rechercheGlobale"
            placeholder="Rechercher par nom, prénom, fonction ou commentaire..."
            className="form-control"
            onChange={(e) => {
              setRecherche(e.target.value);
            }}
            value={recherche}
            style={{ maxWidth: "450px" }}
          />
          <ButtonIcon
            smallText=""
            icon={faTimes}
            iconSize="1x"
            onClick={() => {
              setRecherche("");
            }}
            className="btn btn-danger"
          />
        </div>

        <>
          <div
            className="d-flex flex-row align-items-center"
            style={{
              width: "600px",
            }}
          >
            <div
              style={{
                width: "230px",
                flexShrink: "0",
              }}
            >
              Ajouter un contact existant :
            </div>
            <Input
              type="selectSearch"
              service={(p) => ContactService.getAll({ global: p, size: 100 })}
              valueFieldToDisplay={["nom_Contact", "prenom_Contact", "email"]}
              optionFieldToDisplay={["nom_Contact", "prenom_Contact", "email"]}
              handleChange={handleSelectContact}
            />
          </div>

          <div className="ms-1 d-flex flex-row align-items-center" role="group">
            <div
              style={{
                width: "230px",
                flexShrink: "0",
              }}
            >
              Créer un nouveau contact :
            </div>
            <ButtonIcon
              icon={faPlus}
              iconSize="lg"
              className="btn btn-success text-light"
              onClick={handleAddContact}
            />
          </div>
        </>
      </div>
      <hr />
      <div className="row">{CartesContact()}</div>
    </>
  );
};

export { Contacts };
