import React from "react";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
} from "_components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import {
  FournisseurDomaineService,
  FournisseurQualificationService,
  FournisseurService,
  LangueService,
  PaysService,
  ProduitInterneService,
} from "_services/";
import { Helmet } from "react-helmet";

class BaseFournisseurs extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base Fournisseur</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Fournisseur"
          titreFiltre="Base Fournisseur"
          service={FournisseurService.getAll}
          // redirectToCreate={() => {
          //   RedirectTo(this.props.history, "/articles/add");
          // }}
          orderByDefault="nom"
          localStorageKeyColumns="BaseFournisseurColumns"
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Reférence",
              accessor: "nom",
              search: "nom",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/fournisseurs/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Compte tiers",
              accessor: "compteTier",
              search: "compteTier",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
              Cell: (row) => {
                return row.cell.value;
              },
            },
            {
              Header: "Qualification",
              accessor: "qualification",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              search: "multipleSelectSearch",
              defaultValue: [],
              service: FournisseurQualificationService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Domaines",
              accessor: "domaines",
              hideable: true,
              sortable: false,
              exportable: true,
              minWidth: "150px",
              type: "multipleSelectSearch",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              search: "domaines",
              service: FournisseurDomaineService.getAll,
              defaultValue: [],
              Cell: (row) => {
                return row.cell.value ? (
                  <span>
                    {row.cell.value.map((e) => e.designation).join(", ")}
                  </span>
                ) : null;
              },
            },
            {
              Header: "Produits internes",
              accessor: "produitsInternes",
              hideable: true,
              sortable: false,
              exportable: true,
              minWidth: "150px",
              type: "multipleSelectSearch",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              search: "produitsInternes",
              defaultValue: [],
              service: ProduitInterneService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>
                    {row.cell.value.map((e) => e.designation).join(", ")}
                  </span>
                ) : null;
              },
            },
            {
              Header: "Langue",
              accessor: "langue",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToDisplay: "langage",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "langage",
              search: "langue",
              defaultValue: null,
              service: LangueService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.langage}</span>
                ) : null;
              },
            },
            {
              Header: "Score total",
              accessor: "scoreTotal",
              hideable: true,
              sortable: true,
              exportable: true,
              defaultValue: null,
              minWidth: "150px",
              type: "decimalTable",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.langage}</span>
                ) : null;
              },
            },
            {
              Header: "Ville",
              accessor: "adresse.ville",
              search: "ville",
              hideable: true,
              sortable: true,

              exportable: true,
              minWidth: "170px",
              type: "text",
            },
            {
              Header: "Pays",
              accessor: "adresse.pays",
              search: "pays",
              hideable: true,
              sortable: true,

              exportable: true,
              minWidth: "170px",
              type: "multipleSelectSearch",
              defaultValue: null,
              optionFieldToDisplay: "nom_Fr",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "nom_Fr",
              customFilter: (options) =>
                customFuseSearch(options, {
                  threshold: 0.4,
                  ignoreLocation: true,
                }),
              service: PaysService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.nom_Fr}</span>
                ) : null;
              },
            },
            {
              Header: "Confidentialité RH",
              accessor: "confidentialiteRH",
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
            {
              Header: "Actif",
              accessor: "actif",
              defaultValue: {
                id: 0,
                display: "Oui",
                value: true,
              },
              hideable: true,
              exportable: true,
              minWidth: "180px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

export { BaseFournisseurs };
