import React from "react";

import { BoxInformationsInline } from "_components";
import { PersonnelService, ProduitInterneService } from "_services";

import { BoxContrats } from "./SubTable/Contrats";
import { BoxDatesQuestionnaire } from "./SubTable/DatesQuestionnaire";
import { BoxScope3 } from "./SubTable/Scope3";

// const LigneScope3 = (props) => {
//   return (
//     <tr className="ligneFournisseurContrat">
//       <td className="col1 background-color">
//         <Input
//           accessor={"" + props.scope.id + ".libelle"}
//           value={props.contrat.libelle}
//           type="text"
//           placeholder=""
//           handleChange={props.handleChange}
//           showValidator={false}
//           showClearButton={false}
//           disabled={!props.editing}
//         />
//       </td>
//       <td className="col3 background-color">
//         <Input
//           accessor={"" + props.scope.id + ".date"}
//           value={props.contrat.dateFin}
//           type="date"
//           placeholder=""
//           handleChange={props.handleChange}
//           showValidator={false}
//           showClearButton={false}
//           disabled={!props.editing}
//         />
//       </td>
//       <td className="col3 background-color">
//         <Input
//           accessor={"" + props.scope.id + ".facteurEmission"}
//           value={props.contrat.dateFin}
//           type="decimalTable"
//           placeholder=""
//           handleChange={props.handleChange}
//           showValidator={false}
//           showClearButton={false}
//           disabled={!props.editing}
//         />
//       </td>
//       <td className="col4 background-color" style={{ width: "55px" }}>
//         <ButtonIcon
//           smallText=""
//           icon={faTrash}
//           iconSize="sm"
//           onClick={() => {
//             props?.deleteLineEnfantPersonnel(props.contrat.id);
//           }}
//           className="btn btn-danger deleteLineEnfantPersonnel"
//           style={!props.editing ? { display: "none" } : {}}
//         />
//       </td>
//     </tr>
//   );
// };

const Gestion = (props) => {
  const blocInfoSolsol = [
    {
      label: "Produits internes",
      accessor: "produitsInternes",
      functionAppliedToValue: (value) => {
        return value && value.length > 0 ? (
          <>{value?.map((e) => e.designation).join(", ")}</>
        ) : (
          "-"
        );
      },
      colSize: 4,
      service: ProduitInterneService.getAll,
      optionFieldToDisplay: "designation",
      valueFieldToDisplay: "designation",
      type: "multipleSelectSearch",
    },

    {
      label: "Référent interne",
      accessor: "referentInterne",
      functionAppliedToValue: (value) => {
        return value ? value.nom + " " + value.prenom : null;
      },
      colSize: 4,
      service: PersonnelService.getAll,
      optionFieldToDisplay: ["nom", "prenom"],
      valueFieldToDisplay: ["nom", "prenom"],
      type: "selectSearch",
    },
  ];
  const blocJuridique = [
    {
      label: "NDA",
      accessor: "nda",
      colSize: 4,
      type: "checkbox",
      functionAppliedToValue: (value) => {
        return value ? "Oui" : "Non";
      },
    },
    {
      label: "Date de fin NDA",
      accessor: "dateFinNDA",
      colSize: 4,
      type: "date",
    },
    {
      label: "Confidentialité RH",
      accessor: "confidentialiteRH",
      colSize: 4,
      type: "checkbox",
      functionAppliedToValue: (value) => {
        return value ? "Oui" : "Non";
      },
    },
    {
      label: "Indépendance capitalistique",
      accessor: "independanceCapitalistisque",
      colSize: 4,
      type: "checkbox",
      functionAppliedToValue: (value) => {
        return value ? "Oui" : "Non";
      },
    },
    {
      label: "Rang FRN",
      accessor: "rangFRN",
      colSize: 4,
      type: "selectSearch",
      options: [
        {
          id: 1,
          value: 1,
        },
        {
          id: 2,
          value: 2,
        },
      ],
      optionFieldToDisplay: "value",
      valueFieldToDisplay: "value",
      optionFieldToReturn: "value",
      functionAppliedToValue: (value) => {
        return value ?? "-";
      },
    },
  ];

  const blocRSE = [
    {
      label: "Politique RSE",
      accessor: "politiqueRSE",
      colSize: 4,
      type: "checkbox",
      functionAppliedToValue: (value) => {
        return value ? "Oui" : "Non";
      },
    },
    {
      label: "RSE - date de mise à jour",
      accessor: "dateMaJRSE",
      colSize: 4,
      type: "date",
    },
    {
      label: "Scope 3",
      accessor: "scope3",
      colSize: 4,
      type: "checkbox",
      functionAppliedToValue: (value) => {
        return value ? "Oui" : "Non";
      },
    },
    {
      label: "Retour questionnaire Sol",
      accessor: "retourQuestionnaireSol",
      colSize: 4,
      type: "checkbox",
      functionAppliedToValue: (value) => {
        return value ? "Oui" : "Non";
      },
    },
  ];
  const blocQualite = [
    {
      label: "Accréditation qualité",
      accessor: "accreditationQualite",
      colSize: 5,
      type: "text",
      colSizeInnerFields: 4,
    },
    {
      label: "Date Fin Accreditation Qualite",
      accessor: "dateFinAccreditationQualite",
      colSize: 5,
      type: "date",
      colSizeInnerFields: 4,
    },
    {
      label: "Certification Qualite",
      accessor: "certificationQualite",
      colSize: 5,
      type: "text",
      colSizeInnerFields: 4,
    },
    {
      label: "Date Fin Certification Qualite",
      accessor: "dateFinCertificationQualite",
      colSize: 5,
      type: "date",
      colSizeInnerFields: 4,
    },
    {
      label: "Certification Organisme CIR",
      accessor: "certificationOrganismeCIR",
      colSize: 5,
      type: "text",
      colSizeInnerFields: 4,
    },
    {
      label: "Date Fin Certification Organisme CIR",
      accessor: "dateFinCertificationOrganismeCIR",
      colSize: 5,
      type: "date",
      colSizeInnerFields: 4,
    },

    {
      label: "Dates fermeture Estivale",
      accessor: "datesFermetureEstivale",
      colSize: 5,
      type: "period",
    },

    {
      label: "Dates fermeture hivernale",
      accessor: "datesFermetureHivernale",
      colSize: 5,
      type: "period",
    },
    {
      label: "Commentaire",
      accessor: "commentaire",
      colSize: 10,
      type: "textArea",
    },
  ];

  return (
    <>
      <div className="row ficheMateriel">
        <div className="col-12 ">
          <BoxInformationsInline
            title="Info Sol Sol"
            datas={blocInfoSolsol}
            state={props.fournisseur}
            stateFieldNameToUpdate={props.stateFieldNameToUpdate}
            service={props.service}
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
          />
        </div>
        <div className="col-6 ">
          <BoxInformationsInline
            title="Juridique"
            datas={blocJuridique}
            state={props.fournisseur}
            stateFieldNameToUpdate={props.stateFieldNameToUpdate}
            service={props.service}
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
          />
          <BoxInformationsInline
            title="RSE"
            datas={blocRSE}
            state={props.fournisseur}
            stateFieldNameToUpdate={props.stateFieldNameToUpdate}
            service={props.service}
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
          />
        </div>
        <div className="col-6 ">
          <BoxContrats
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
            fournisseur={props.fournisseur}
          />
        </div>
        <div className="col-12 "></div>
        <div className="col-6 ">
          <BoxDatesQuestionnaire
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
            fournisseur={props.fournisseur}
          />
        </div>
        <div className="col-6 ">
          <BoxScope3
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
            fournisseur={props.fournisseur}
          />
        </div>
        <div className="col-12 ">
          <BoxInformationsInline
            title="Qualité"
            datas={blocQualite}
            state={props.fournisseur}
            stateFieldNameToUpdate={props.stateFieldNameToUpdate}
            service={props.service}
            handleChange={props.handleChange}
            handleEditing={props.handleEditing}
            editing={props.editing}
            handleUpdate={props.handleUpdate}
          />
        </div>
      </div>
    </>
  );
};

export { Gestion };
